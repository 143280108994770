import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FormCheck } from "react-bootstrap";

import { first, intVal, Nullable, removeProp } from "@jamesgmarks/utilities";
import { getMonthName } from "../../../app-utils";
import { IBuildingBillingTypes, IBuildings } from "@llws/typeorm-entities/dist/interfaces/lift";

import { BillingTypeHistoryModal } from "./BillingTypeHistoryModal";
import { SplitButton, SplitButtonItem } from "../../parts/SplitButton";
import { updateClientBillingTypeHistories } from "../../../redux/features/clients/actions";

const BillingTypesListDataRow = ({
  checked,
  property,
  history,
  setSelected,
  billingTypes,
  editProperty,
}: {
  checked: boolean,
  property: IBuildings,
  history: IBuildings,
  setSelected: ((buildingId: number, selected: boolean) => void),
  billingTypes: IBuildingBillingTypes[],
  editProperty: (item: IBuildings) => unknown,
}) => {

  const getMostRecentBuildingHistory = () => {
    return first(
      history
        .buildingsBillingTypeHistories
        ?.slice()
        .sort((a, b) => {
          return a.startTimestamp > b.startTimestamp ? -1 : 1;
        }) ?? [],
    );
  };

  const mostRecentBillingHistory = getMostRecentBuildingHistory();
  const mostRecentBillingTypeName = (
    billingTypes.find(bt => bt.id === mostRecentBillingHistory?.billingTypeId)?.typeName
  );
  const mostRecentBillingTypeStart = (
    mostRecentBillingHistory?.startTimestamp
      ? new Date(mostRecentBillingHistory?.startTimestamp)
      : null
  );

  return (
    <>
      <TableRow>
        <TableCell style={{ textAlign: "center" }}>
          <FormCheck
            checked={checked}
            onChange={(e) => {
              if (property.id) {
                setSelected(property.id!, e.target.checked);
              }
            }}
          />
        </TableCell>
        <TableCell>{property.id}</TableCell>
        <TableCell>{property.buildingName}</TableCell>
        <TableCell>
          {mostRecentBillingTypeName}
        </TableCell>
        <TableCell>
          {
            mostRecentBillingTypeStart
          && getMonthName(mostRecentBillingTypeStart) + ', ' + mostRecentBillingTypeStart.getFullYear()
          }
        </TableCell>
        <TableCell>
          <Grid container justifyContent="flex-end" alignContent="flex-end">
            <Grid item>
              <SplitButton variant="dots" onSelect={(id, index) => {
                console.log({ id, index });
              }}>
                <SplitButtonItem label="Update" id="update" onClick={() => {
                  console.log('Clicked update button: ', { property });
                  editProperty(history);
                }} />
                <SplitButtonItem label="View History" id="history" />
              </SplitButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </>
  );
};

export const BillingTypeHistory = ({
  properties, history, billingTypes,
} : {
  properties: IBuildings[],
  history: IBuildings[],
  billingTypes: IBuildingBillingTypes[],
}) => {
  const { clientId } = useParams<{ clientId: string }>();

  const [ allChecked, setAllChecked ] = useState(false);
  const [ selectedProperties, setSelectedProperties ] = useState({} as Record<number, boolean>);
  const [ page, setPage ] = useState(1);
  const [ pageSize ] = useState(50);

  const [ showModal, setShowModal ] = useState(false);
  const [ modalProperty, setModalProperty ] = useState(null as Nullable<IBuildings>);

  useEffect(() => {
    setAllChecked(
      !(properties.length > Object.keys(selectedProperties).filter(k => selectedProperties[intVal(k)]).length),
    );
  }, [selectedProperties, properties]);

  const maxPages = Math.ceil(properties.length / pageSize);

  const getHistoryForProperty = (property: IBuildings) => {
    const _history = history.filter(h => h.id === property.id);
    if (_history.length === 0) {
      console.error(`History for property ${property.id} does not exist`);
    }
    if (_history.length > 1) {
      console.error(`History for property ${property.id} has multiple items`);
    }
    return first(_history)!;
  };

  const sortBy = (label: string) => { };
  const getSortIcon = (label: string) => { return <></>; };

  const toggleAllPropertyCheckboxes = () => {
    const checkedBuildings = (
      !allChecked
        ? (
          properties.reduce((output, property) => {
            return {
              ...output,
              [property.id!]: true,
            };
          }, {} as Record<number, boolean>)
        ) : {}
    );
    setSelectedProperties(checkedBuildings);
  };
  const doSort = (a: IBuildings, b: IBuildings) => { return 1; };

  const setSelected = (buildingId: number, selected: boolean) => {
    const _selectedBuildings = removeProp(selectedProperties, buildingId);
    console.log({ buildingId, selected });
    setSelectedProperties({
      ..._selectedBuildings,
      [buildingId]: selected ?? false,
    } as Record<number, boolean>);
  };

  // const pageSizes = [ 50, 100, 250, 500 ];

  const getSelectedPropertyIds = () => (
    Object.keys(selectedProperties).filter(sel => selectedProperties[intVal(sel)]).map(intVal)
  );

  const selectedPropertyIds = getSelectedPropertyIds();
  const modalProps = (
    !!modalProperty
      ? { property: modalProperty }
      : { properties: properties.filter(p => ( selectedPropertyIds.includes(p.id ?? -1) )) }
  );

  return (
    <>
      {
        showModal
        && <BillingTypeHistoryModal
          // property={modalProperty!}
          // properties={properties && }
          {...modalProps}
          billingTypes={billingTypes}
          open={showModal}
          onSaveChanges={(newStartDate, newType) => {
            const saveIds = (!!modalProperty ? [ modalProperty.id! ] : selectedPropertyIds);
            // call the save action for this.
            updateClientBillingTypeHistories({
              clientId: intVal(clientId),
              propertyIds: saveIds,
              billingTypeId: newType,
              startDate: newStartDate,
            });
            return true;
          }}
          onClose={() => { setShowModal(false); }}
        />
      }
      <Grid container>
        <Grid item xs={4}>
          <Grid container justifyContent="flex-start" alignContent="flex-start">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setPage(Math.max(page - 1, 1))}
              >&lt;</Button>
              {' Page '}{page}{' of '}{maxPages}{' '}
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setPage(Math.min(page + 1, maxPages))}
              >&gt;</Button>

            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container justifyContent="center" alignContent="center">
            <Grid item >
              {((page - 1) * pageSize) + 1}-{((page) * pageSize)} of {properties.length}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          {/* <Grid container justify="flex-end">
            <Grid item alignContent="flex-end">
              {' '} Page Size: <SplitButton variant="dots" onSelect={(id, index) => {
                setPageSize(intVal(id.split('-')[0]));
              }} defaultItemIndex={pageSizes.indexOf(pageSize)}>
                {pageSizes.map(size => <SplitButtonItem id={`${size}-page`} label={`${size}`} />)}
              </SplitButton>
            </Grid>
          </Grid> */}
          <Grid container justifyContent="flex-end" alignContent="flex-end">
            <Grid item>
              {Object.values(selectedProperties).filter(selectedBuilding => !!selectedBuilding).length} Selected{' '}
              <SplitButton onSelect={(id, index) => {
                console.log('onSelect: ', { id, index });
                const selectedIds = Object.keys(selectedProperties).filter((k) => selectedProperties[intVal(k)]);
                switch (id) {
                case 'massEdit':
                  console.log({ selectedIds });
                  if (selectedIds.length > 0) {
                    setShowModal(true);
                  }
                  break;
                }
              }} >
                <SplitButtonItem id='massEdit' label='Mass Edit' />
                <SplitButtonItem id='selectPage' label={`Select ${pageSize} properties from this page.`} />
              </SplitButton>{` `}
            </Grid>
          </Grid>

        </Grid>

        <Grid item xs={12}>
          <TableContainer>
            <Table style={{ borderCollapse: 'collapse'}}>
              <TableHead>
                <TableRow style={{ cursor: 'pointer' }}>
                  <TableCell style={{ textAlign: 'center' }} onClick={() => toggleAllPropertyCheckboxes()}>
                    <FormCheck
                      checked={allChecked && (properties ?? []).length !== 0}
                      onChange={() => toggleAllPropertyCheckboxes()}
                    />
                  </TableCell>
                  <TableCell
                    // onClick={(e) => sortBy('invoiceNumber')}
                    style={{ whiteSpace: 'nowrap' }}
                  >ID {getSortIcon('invoiceNumber')}</TableCell>
                  <TableCell
                    onClick={(e) => sortBy('clientName')}
                    style={{ whiteSpace: 'nowrap' }}
                  >Name {getSortIcon('clientName')}</TableCell>
                  <TableCell
                    onClick={(e) => sortBy('ownershipGroupId')}
                    style={{ whiteSpace: 'nowrap' }}
                  >Type {getSortIcon('ownershipGroupId')}</TableCell>
                  <TableCell
                    onClick={(e) => sortBy('invoiceDate')}
                  >Start {getSortIcon('invoiceDate')}</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(properties || [])
                  .slice(0 + ((page - 1) * pageSize), (page * pageSize))
                  .sort(doSort)
                  .map((property) => (
                    <BillingTypesListDataRow
                      key={property.id}
                      property={property}
                      history={getHistoryForProperty(property)}
                      checked={selectedProperties[property.id || -1] ? true : false}
                      setSelected={setSelected}
                      billingTypes={billingTypes}
                      editProperty={(item: IBuildings) => {
                        setModalProperty(item);
                        setShowModal(true);
                      }}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};