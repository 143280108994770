import { Nullable } from '@jamesgmarks/utilities';
import { IOwnershipGroups } from '@llws/typeorm-entities/dist/interfaces/lift';
import { CreditApplied } from '../../../entities/hydra/CreditApplied';
import { EInvoiceGenerationType } from '../../../entities/hydra/Invoice';
import { IBillingAccount } from './IBillingAccount';
import { IInvoiceRaw } from './IInvoiceRaw';
import { IInvoiceSendHistory } from '../interfaces/IInvoiceSendHistory';
import { InvoiceLine, Payment } from '../../../entities/hydra';
import { IPdfGeneration } from './IPdfGeneration';

export enum EHydraState {
  deleted = 'deleted',
  unsent = 'unsent',
  sent = 'sent',
  'legacy-imported' = 'legacy-imported',
  zeroInvoice = 'zero-invoice',
  unknown = 'unknown',
  sendingEmail = 'sendingEmail',
}

export interface IInvoice {
  id: number;
  freshbooksClientId: number;
  thirdPartyInvoiceId: string;
  ownershipGroupId?: number | null;
  invoiceNumber: string;
  metaData: string;
  hydraState: string;
  freshbooksState: string;
  invoiceDate: string;
  amountInvoiced: string;
  taxes: string;
  currency: string;
  notes: string;
  // rawCostingData: string;
  runId: number;
  taxName: string;
  taxRate: string;
  generationType: EInvoiceGenerationType;

  created: string;
  createdUserId: Nullable<number>;
  modified: Nullable<string>;
  modifiedUserId: Nullable<number>;

  creditsApplied?: CreditApplied[];
  invoiceRaw?: IInvoiceRaw;
  billingAccount: IBillingAccount;
  pdfGenerations?: IPdfGeneration[];
  ownershipGroup?: IOwnershipGroups;
  invoiceSendHistory: IInvoiceSendHistory[];
  payments: Payment[];
  lines:InvoiceLine[]

}

/** Type guard for whether an object is a `IInvoice`. */
export const isIInvoice = (object: unknown): object is IInvoice => !!(object as IInvoice).invoiceNumber;

/** Type guard for whether an array contains only `IInvoice`s. */
export const isIInvoiceArray = (object: unknown[]): object is IInvoice[] => object.every(isIInvoice);

export const assertIInvoiceArray = (arr: unknown[]): IInvoice[] | never => {
  if (!isIInvoiceArray(arr)) {
    throw new Error('This is not an array of `IInvoice`!');
  }

  return arr;
};
