import { Hash, keys } from "@jamesgmarks/utilities";
import { IOwnershipGroups } from "@llws/typeorm-entities/dist/interfaces/lift";
import { ReactNode } from "react";
import { Invoice } from "../../../../../entities/hydra";
import { IBillingAccount } from "../../../entity-interfaces/IBillingAccount";
import { IAugmentedCreditNote } from "../../../interfaces/ICreditNote";

export enum EDocument {
  invoice = 'invoice',
  creditNote = 'creditNote',
}

export type TDocumentName = keyof typeof EDocument;

/** Type guard for valid document names. */
const isDocumentName = (x: string): x is TDocumentName => keys(EDocument).includes(x as TDocumentName);

/** Type guard for an array of valid document names. */
const isDocumentNameArray = (x: string[]): x is TDocumentName[] => (
  x.every((document) => isDocumentName(document))
);

/** Asserts that an array of `string`s contains only valid `TDocumentName`s.
 * @param documentNames An array of strings representing document names.
 * @returns The passed-in array if entirely valid, or throws an `Error`.
*/
export const assertValidDocumentNameArray = (documentNames: string[]): TDocumentName[] | never => {
  if (!isDocumentNameArray(documentNames)) {
    throw new Error(
      `Some provided document names are invalid: ${
        documentNames.filter((documentName) => !keys<Hash, string>(EDocument).includes(documentName))
      }`,
    );
  }

  return documentNames;
};

export type TDocumentFieldGetter<T> = keyof T | ((entity: T) => ReactNode);

/** This defines the entity types that can be returned to the Redux action.
 * It should be updated as more document types are desired. */
export interface IDocumentMap {
  invoice: (Invoice & { billingAccount: IBillingAccount, ownershipGroup?: IOwnershipGroups })[];
  creditNote: IAugmentedCreditNote[];
};
