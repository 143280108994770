import { IPartners } from "@llws/typeorm-entities/dist/interfaces/lift";
// import { useState } from "react";
import { Table, Button, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { loadMorePartners } from "../../../redux/features/partners/actions";
import { useAppSelector } from "../../../redux/hooks";

const PartnersListDataRow = ({ partner }: { partner: IPartners }) => {
  return (
    <tr>
      <td>{partner.id}</td>
      <td>{partner.freshbooksClientId}</td>
      <td>{partner.partnerKey}</td>
      <td>{partner.name}</td>
      <td>{partner.status}</td>
      <td>
        <Link to={`/partners/${partner.id}`}>View</Link> &nbsp;
        {/* | <span><Link to={`/invoices?oldFreshbooksClientId=${partner.freshbooksClientId}`}>Invoices</Link></span> */}
      </td>
    </tr>
  );
};

export const PartnersListView = ({ partners }: { partners: IPartners[] }) => {
  const nextPage = useAppSelector(state => state.partners.nextPage);

  // const [searchIdFilter, setSearchIdFilter] = useState(undefined as number | undefined);
  // const [searchNameFilter, setSearchNameFilter] = useState(undefined as string | undefined);

  return (<>
    <Container>
      {/* <Row>
        <Col>
          <Form onSubmit={(e) => {
            e.preventDefault();
            setClientFilter('searchIdFilter', searchIdFilter);
            setClientFilter('searchNameFilter', searchNameFilter);
          }}>
            <Row>
              <Col sm>Client ID:</Col>
              <Col sm>
                <Form.Control
                  type="number"
                  placeholder="DB/FB ID"
                  value={searchIdFilter}
                  onChange={(e) => setSearchIdFilter(e.target.value ? intVal(e.target.value) : undefined)} />
              </Col>
              <Col xs={4}></Col>
            </Row>
            <Row>
              <Col sm>Client Name:</Col>
              <Col sm>
                <Form.Control
                  type="string"
                  placeholder="name"
                  value={searchNameFilter}
                  onChange={(e) => setSearchNameFilter(e.target.value ? e.target.value : undefined)} />
              </Col>
              <Col xs={4}></Col>
            </Row>
            <Row>
              <Col><Button type="submit">Search</Button></Col>
            </Row>
          </Form>
        </Col>
      </Row> */}
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>ID</th>
            <th>FB ID</th>
            <th>key</th>
            <th>Name</th>
            <th>Status</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {(partners || []).map(partner => <PartnersListDataRow key={partner.id} partner={partner} />)}
        </tbody>
        {nextPage && <tfoot>
          <tr>
            <th colSpan={7}><Button type="button" onClick={() => loadMorePartners()}>More...</Button></th>
          </tr>
        </tfoot>}
      </Table>
    </Container>
  </>);
};