import { createSlice } from '@reduxjs/toolkit';
import {
  IBuildingBillingTypes,
  IBuildings,
  IBuildingTypes,
  IClientDefaultBillingTypes,
  IClients,
} from '@llws/typeorm-entities/dist/interfaces/lift';
import { IApiQueryResponse } from '@llws/api-common';
import { arrayWrap, Hash, keys, Nullable, pickNot } from '@jamesgmarks/utilities';
import { ClientHasBillingContacts, OwnershipGroups } from '@llws/typeorm-entities';

import { IActionType, IApiQueryListResponse, IApiQuerySingularResponse } from '../../utils';
import { IBillingAccount } from '../../../entity-interfaces/IBillingAccount';
import { IClientDropdownItem } from '../../../entity-interfaces/IClientDropdownItem';
import { IClientMatch } from '../../../../../rentsync-api/IClientMatch';
import { IOwnershipGroupDropdownItem } from '../../../entity-interfaces/IOwnershipGroupDropdownItem';
import { Invoice, InvoiceLine, LiftClient } from '../../../../../entities/hydra';

export enum EClientLoadState {
  idle = 'idle',
  loading = 'loading',
  loaded = 'loaded',
}

export enum EClientMigrateState {
  idle = 'idle',
  migrating = 'migrating',
  migrated = 'migrated',
}

export interface ClientBillingTypesSummary {
  client: IClients,
  buildingTypes: IBuildingTypes[],
  billingTypes: IBuildingBillingTypes[],
  clientBuildingTypeDefaults: IClientDefaultBillingTypes[],
}

export interface IRecentInvoice extends Invoice {
  amountCredited: number;
  lines: InvoiceLine[];
  ownershipGroup: OwnershipGroups;
};

export interface IClientsState {
  loadedState: EClientLoadState,
  migratingState: EClientMigrateState,
  clientsList: IClientMatch[],
  clientDropDownSource: IClientDropdownItem[],
  billingContactsList?: ClientHasBillingContacts[],
  ownershipGroupsList?: IOwnershipGroupDropdownItem[],
  isLastMonthLocked:boolean,
  nextPage: Nullable<string>,
  filters: Hash,

  currentClient: Nullable<IClients>,
  currentClientBillingAccount: Nullable<IBillingAccount>,
  currentClientBillingAccountRecentInvoices: IRecentInvoice[],
  currentClientProperties: Nullable<IBuildings[]>,
  currentClientBuildingTypeHistory: Nullable<IBuildings[]>,
  currentClientBillingTypes: Nullable<ClientBillingTypesSummary>,

  currentHydraClient: Nullable<LiftClient>,
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    loadedState: EClientLoadState.idle,
    migratingState: EClientMigrateState.idle,
    clientsList: [],
    clientDropDownSource: [],
    ownershipGroupsList: [],
    isLastMonthLocked: true,
    nextPage: null,
    filters: {},

    currentClient: null,
    currentClientBillingAccount: null,
    currentClientBillingAccountRecentInvoices: [],
    currentClientProperties: null,
    currentClientBuildingTypeHistory: null,
    currentClientBillingTypes: null,

    currentHydraClient: null,
  } as IClientsState,
  reducers: {
    clientsDropDownListReceived: (state, action: IActionType<IApiQueryResponse<IClientDropdownItem>>) => {
      state.clientDropDownSource = arrayWrap(action.payload.data);
    },
    clientsListReceived: (state, action: IActionType<IApiQueryResponse<IClientMatch>>) => {
      state.clientsList = arrayWrap(action.payload?.data ?? []);
      state.nextPage = action.payload?.meta?.links.next ?? null;
    },
    clientBillingContactDataReceived: (state, action: IActionType<IApiQueryResponse<ClientHasBillingContacts>>) => {
      state.billingContactsList = arrayWrap(action.payload.data);
    },
    clientOwnershipGroupsDataReceived: (state, action: IActionType<IApiQueryResponse<IOwnershipGroupDropdownItem>>) => {
      state.ownershipGroupsList = arrayWrap(action.payload.data);
    },
    clearClientBillingContactList: (state, action: { type: string, payload: void }) => {
      state.billingContactsList = [];
    },
    clearClientOwnershipGroupsList: (state, action: { type: string, payload: void }) => {
      state.ownershipGroupsList = [];
    },
    isLastMonthLockedReceived: (state, action: IActionType<IApiQueryResponse<boolean>>) => {
      state.isLastMonthLocked = (action.payload.data as boolean ?? null);
    },
    moreClientsReceived: (state, action: IActionType<IApiQueryResponse<IClientMatch>>) => {
      state.clientsList = state.clientsList.concat(arrayWrap(action.payload.data));
      state.nextPage = action.payload.meta.links.next;
      state.loadedState = EClientLoadState.loaded;
    },
    setLoadedState: (state, action: IActionType<EClientLoadState>) => {
      state.loadedState = action.payload;
    },
    setMigratingState: (state, action: IActionType<EClientMigrateState>) => {
      state.migratingState = action.payload;
    },
    setClientsFilters: (state, action: IActionType<Hash>) => {
      const deleteKeys = keys(action.payload).filter(k => !action.payload[k]);
      const filteredFilters = pickNot(state.filters, deleteKeys);

      state.filters = {
        ...filteredFilters,
        ...pickNot(action.payload, deleteKeys),
      };
      // console.info({ newFilters: state.filters });
    },
    currentClientReceived: (state, action: IActionType<IApiQueryResponse<IClients>>) => {
      state.currentClient = (action.payload.data as IClients ?? null);
    },
    currentClientBillingAccountReceived: (state, action: IActionType<IBillingAccount>) => {
      state.currentClientBillingAccount = action.payload;
    },
    currentClientBillingAccountRecentInvoicesReceived: (state, action: IActionType<IRecentInvoice[]>) => {
      state.currentClientBillingAccountRecentInvoices = action.payload;
    },
    currentClientPropertiesReceived: (state, action: IActionType<IApiQueryListResponse<IBuildings>>) => {
      state.currentClientProperties = action.payload.data;
    },
    currentClientBuildingTypeHistoryReceived: (state, action: IActionType<IApiQueryListResponse<IBuildings>>) => {
      state.currentClientBuildingTypeHistory = action.payload.data;
    },
    currentClientBillingTypeSummaryReceived: (
      state,
      action: IActionType<IApiQuerySingularResponse<ClientBillingTypesSummary>>,
    ) => {
      state.currentClientBillingTypes = action.payload.data;
    },
    currentHydraClientReceived: (state, action: IActionType<IApiQueryResponse<LiftClient>>) => {
      state.currentHydraClient = (action.payload.data as LiftClient ?? null);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearClientBillingContactList,
  clearClientOwnershipGroupsList,
  clientBillingContactDataReceived,
  clientOwnershipGroupsDataReceived,
  clientsDropDownListReceived,
  clientsListReceived,
  currentClientBillingAccountReceived,
  currentClientBillingAccountRecentInvoicesReceived,
  currentClientBillingTypeSummaryReceived,
  currentClientBuildingTypeHistoryReceived,
  currentClientPropertiesReceived,
  currentClientReceived,
  currentHydraClientReceived,
  isLastMonthLockedReceived,
  moreClientsReceived,
  setClientsFilters,
  setLoadedState,
  setMigratingState,
} = clientsSlice.actions;

export default clientsSlice.reducer;
