import { IApiQueryResponse } from '@llws/api-common';
import { IPartners } from '@llws/typeorm-entities/dist/interfaces/lift';
import { dispatch } from '../../store';
import { partnersListReceived, morePartnersReceived } from './partnersSlice';
import { store } from '../../store';
import { keys } from '@jamesgmarks/utilities';
import { apiFetch } from '../../utils';
import { REACT_APP_API_ROOT_URI } from '../../../App';

const getFilterQueryStringItems = () => {
  const filters = store.getState().partners.filters || {};
  const queryString = filters ? `?${keys(filters).map(f => `${f}=${filters[f]}`).join('&')}` : '';
  return queryString;
};

export const loadPartners = async () => {
  const queryString = getFilterQueryStringItems();
  const url = `${REACT_APP_API_ROOT_URI}/rentsync/partners${queryString}`;
  console.log(`Loading partners.`, { url } );
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<IPartners>;
  console.log({ status: response.status, responseData });
  dispatch(partnersListReceived(responseData)); // TODO: Error handling?
};

export const loadMorePartners = async () => {
  const queryString = getFilterQueryStringItems();
  const url = `${REACT_APP_API_ROOT_URI}${store.getState().partners.nextPage}${queryString}`;
  console.log(`Loading clients.`, { url } );
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<IPartners>;
  console.log({ status: response.status, responseData });
  dispatch(morePartnersReceived(responseData)); // TODO: Error handling?
};
