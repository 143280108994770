import { createSlice } from '@reduxjs/toolkit';
// import { IPartners } from '@llws/typeorm-entities/dist/interfaces/lift'
import { IApiQueryResponse } from '@llws/api-common';
import { arrayWrap, HashOf, Nullable } from '@jamesgmarks/utilities';
import { IMonthlySummaryBreakdownItem, IMonthlySummaryData } from './IMonthlySummaryData';
import { MonthEndLocks } from '@llws/typeorm-entities';
import { IActionType } from '../../utils';
import { IPartnerCallTrackingLineSummary } from '../../../../../rentsync-api/reporting/partnerCallTrackingLineSummary';
import { ICustomRunnerBreakdown } from '../../../../../custom_runners/ICustomRunnerBreakdown';

export interface IApiData<T> {
  loadedState: string,
  apiData: T,
}

export interface IReportingState {
  loadedState: string,
  monthlySummary: Nullable<IMonthlySummaryData>,
  year: Nullable<number>,
  month: Nullable<number>,
  monthLockList:Nullable<MonthEndLocks[]>,
  padmapperRevenueSummary: Nullable<{ CAD: HashOf<number>, USD: HashOf<number> }>,
  partnerId: Nullable<number>,
  partnerCallTrackingLineSummary: Nullable<IPartnerCallTrackingLineSummary[]>,
  customReportsBreakdown: Nullable<ICustomRunnerBreakdown[]>,
};

export const reportingSlice = createSlice({
  name: 'reporting',
  initialState: {
    loadedState: 'idle',
    monthlySummary: null,
    year: null,
    month: null,
    monthLockList: null,
    padmapperRevenueSummary: null,
    partnerId: null,
    partnerCallTrackingLineSummary: null,
    customReportsBreakdown: null,
  } as IReportingState,
  reducers: {
    setLoadingState: (state, action: { type: string, payload: string}) => {
      state.loadedState = action.payload;
    },
    setSummaryMonth: (state, action: { type: string, payload: { year: number | null, month: number | null }}) => {
      state.year = action.payload.year;
      state.month = action.payload.month;
    },
    monthlySummaryReceived: (state, action: { type: string, payload: IApiQueryResponse<IMonthlySummaryData> }) => {
      state.monthlySummary = action.payload.data as IMonthlySummaryData;
      state.loadedState = 'loaded';
    },
    monthlySummaryBreakdownDataReceived: (
      state,
      action: { type: string, payload: IApiQueryResponse<IMonthlySummaryBreakdownItem> },
    ) => {
      state.monthlySummary!.subscriptionBreakdown = arrayWrap(action.payload.data);
    },
    padmapperRevenueSummaryReceived: (state, action: IActionType<Nullable<{
      CAD: HashOf<number>;
      USD: HashOf<number>;
    }>>) => {
      state.padmapperRevenueSummary = action.payload;
    },
    monthLockListReceived: (state, action: { type: string, payload: IApiQueryResponse<MonthEndLocks[]> }) => {
      state.monthLockList = action.payload.data as MonthEndLocks[];
    },
    partnerCallTrackingLinesReceived: (
      state,
      action: { type: string, payload: IApiQueryResponse<IPartnerCallTrackingLineSummary[]>},
    ) => {
      state.partnerCallTrackingLineSummary = action.payload.data as IPartnerCallTrackingLineSummary[];
    },
    partnerIdReceived: (state, action: {type: string, payload: number}) => {
      state.partnerId = action.payload;
    },
    customReportsBreakdownReceived: (state, action: IActionType<ICustomRunnerBreakdown[]>) => {
      state.customReportsBreakdown = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  monthlySummaryReceived,
  monthlySummaryBreakdownDataReceived,
  setLoadingState,
  setSummaryMonth,
  monthLockListReceived,
  padmapperRevenueSummaryReceived,
  partnerCallTrackingLinesReceived,
  partnerIdReceived,
  customReportsBreakdownReceived,
} = reportingSlice.actions;

export default reportingSlice.reducer;