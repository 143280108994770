import {
  Paper, InputBase, IconButton, Collapse, Card, Typography, AppBar, Toolbar, Grid, Box,
} from '@mui/material';
import { useState } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { sendMessagesToChatBot } from 'src/redux/features/chat-bot/actions';
import { useAppSelector } from 'src/redux/hooks';
import { ELoadState } from 'src/redux/utils';
import { Spinner } from './Spinner';

export const ChatBot = ({}:{}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userMessage, setUserMessage] = useState('');

  const { messagesFromBot, loadedState } = useAppSelector((state) => state.chatBot);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };
  return (<>
    <Card sx={{ padding: '0px'}}>

      <IconButton onClick={toggleChat} sx={{ position: 'fixed', bottom: '16px', right: '16px' }}>
        {/* Use an icon or button to toggle the chat */}
        {/* You can replace the icon below with your own */}
        {isOpen ? <CloseIcon /> : <ChatIcon  color="primary" />}
      </IconButton>

      {/* Chat component */}
      <Collapse in={isOpen}>
        <Card
          sx={{
            position: 'fixed',
            bottom: '16px',
            right: '16px',
            width: '300px',
            padding: '0px',
            zIndex: '100',
          }}
        >
          <AppBar position="static" sx={{ backgroundColor: '#333', display: 'flex' }}>
            <Toolbar>
              <Typography variant="h6" component="div">
                Hydro - AI Assistant
              </Typography>
              <IconButton onClick={toggleChat} sx={{ marginLeft: 'auto'}}>
                <CloseIcon />
              </IconButton>
            </Toolbar>

          </AppBar>

          <Paper
            sx={{
              padding: '5px',
              marginTop: '20px',
              height: '250px',
              overflow: 'auto',
              display: 'flex',      // Center content horizontally
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {
              loadedState !== ELoadState.loaded
                ?<Box sx={{ justifyContent: 'center', alignItems: 'center', height: '100%'  }}>
                  <Spinner state={loadedState} ></Spinner>
                </Box>
                :<Box sx={{alignItems: 'left'}}>
                  {messagesFromBot.filter((message)=> (
                    !['system', 'tool'].includes(message.role)) 
                   && (message.content?.toString() ?? '').trim() !== '')
                    .map((message) => (
                      <Box sx={{  justifyContent: 'start', alignItems: 'left' }}>
                        <h5>{message.role}</h5>
                        <div dangerouslySetInnerHTML={{ __html: message.content?.toString()  ?? '' }} />
                      </Box>
                    ))}
                </Box>
            }
                              
          </Paper>

          <Grid container>
            <Grid item xs={10}>
              <InputBase
                sx={{ flex: 1, marginLeft: 1  }}
                placeholder="Type your question"
                inputProps={{ 'aria-label': 'Type your question' }}
                multiline
                minRows={3}
                value = {userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                sx={{ padding: 1, flex: 1, marginLeft: 'auto' }}
                onClick={() => sendMessagesToChatBot(messagesFromBot, userMessage)}
              >
                <SendIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Card>
      </Collapse>
    </Card>
  </>);
};