/**
 * Splits a string by its whitespace, and capitalizes the first letter of each word. All other letters will be returned lowercase.
 * @param str
 * @returns A Capitalized String
 */
export const capitalize = (str: string) => (
  str
    .trim()
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
);

/**
 * Checks if the input string can be parsed into a four-character integer.
 * @param str A user-inputted string intended to represent a year.
 * @returns A boolean indicating whether or not the string represents a valid year.
 */
export const validateYearInput = (str: string) => (
  /^[0-9]{0,4}$/
    .test(str.trim())
);

/**
 * Checks if the input string can be parsed into a valid month (1 - 12).
 * @param str A user-inputted string intended to represent a month.
 * @returns A boolean indicating whether or not the string represents a valid month.
 */
export const validateMonthInput = (str: string) => (
  /^([1-9](?!.)|1[0-2])?$/
    .test(str.trim())
);

/**
 * Checks if the input string can be parsed into a valid YYYY-MM-DD string. (Leading zeros are not optinal)
 * @param str A string intended to represent a date string.
 * @returns A boolean indicating whether or not the string represents a valid YYYY-MM-DD date string.
 */
export const isValidYmdString = (str: string) => (
  /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
    .test(str.trim())
);

/**
 * Checks if the input string is in a valid ISO 8601 format.
 * @param str A string intended to represent a ISO date string.
 * @returns A boolean indicating whether or not the string represents a valid ISO date string.
 */
export const isValidIsoString = (str: string) => (
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/
    .test(str.trim())
);
/** Provided a `Date` instance, sets the time of it to the last millisecond of that date. */
export const getEndOfDay = (date: Date) => new Date((new Date(date)).setHours(23, 59, 59, 999));

/** Converts a camelCase string into a capitalized one, with spaces and
 * where the first letter of each word is uppercase. Handles acronyms
 * where multiple upper-case letters may be placed in a row. */
export const camelCaseToCapitalized = (str: string) => {
  const spaced = (
    str
      .replace(/([A-Z])([a-z])/g, ' $1$2') // Handles `aTestVar`
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Handles `anACRONYMVar
  );

  return capitalize(spaced[0]) + spaced.slice(1);
};

/** Converts a capitalized string, where the first letter of each
 * word, only, is capitalized, into a camelCase version. */
export const capitalizedToCamelCase = (str: string) => {
  const spacesRemoved = str.replace(/\s([A-Z])/g, '$1');
  return spacesRemoved[0].toLowerCase() + spacesRemoved.slice(1);
};

/**
 * Extracts the date and/or time part of an ISO timestamp string, without conversion from UTC.
 * Some localized date values in the database become ISO strings upon retrieval.
 * This function avoids the offset upon parsing a `Date`.
 * @param str The ISO timestamp (e.g. '2022-08-24T00:00:00.000Z').
 * @param part Determines what to extract.
 * @returns If 'both' is chosen, an array of both elements, otherwise a `string`.
 */
export const getPartsFromIsoString = (
  str: string,
  part: 'date' | 'time' | 'both' = 'both',
): string | string[] => {
  const parts = str.split('T');

  if (parts.length !== 2) {
    throw new Error('Invalid ISO string provided!');
  }

  const partIndexMap = {
    date: 0,
    time: 1,
    both: 2,
  };

  return (
    parts[partIndexMap[part]] ?? parts
  );
};

/**
 * Takes an object with an `email` property and an optional `name` property.
 * Returns a formatted string with pertinent contact information.
 * @param contact Object with an email and optional name.
 * @returns Formatted string.
 */
export const getEmailStringFromContactInformation = (
  contact: { name?: string, email: string },
): string => (
  contact.name
    ? `${contact.name} <${contact.email}>`
    : `${contact.email}`
);

/**
 * return the up or down pointing black triangle 
 * @param criteria the column name
 * @param sortCriteria the current column that is being sorted
 * @param sortDirection direction of the sort and the triangle
 * @returns return the up or down pointing black triangle 
 */
export const showSortIconIfAvailable = (
  criteria: string,
  sortCriteria: string,
  sortDirection: 'ASC' | 'DESC',
) => {
  return (
    sortCriteria === criteria 
      ? (sortDirection === 'ASC' 
        ? String.fromCodePoint(9650)
        : String.fromCodePoint(9660)) 
      : null
  );
};

/**
 * Provides a functional reduce-like abstraction to work with arrays asynchronously and
 * sequentially.
 * #DuplicateFunction
 */
export const reduceAsync = async <T, U>(
  list: T[],
  handler: ((acc: U, curr: T, currIndex: number, array: T[]) => Promise<U>),
  initialValue: U,
) => {
  let result = initialValue;

  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < list.length; i++) {
    const value = list[i];
    // `await` will transform result of the function to the promise, even it is a synchronous call
    result = await handler(result, value, i, list); // eslint-disable-line no-await-in-loop
  }

  return result;
};
