import { dispatch } from '../../store';
import { IApiQuerySingularResponse, typedApiFetch } from '../../utils';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { setLoadedState, singleViewStatementDataReceived, statementListReceived } from "./statementsSlice";
import { Nullable, first } from '@jamesgmarks/utilities';
import { IStatement } from 'src/entity-interfaces/IStatement';
import { EStatementType, ISingleViewStatementData } from 'src/interfaces/ISingleViewStatementData';
import { IShowMessageProps, showMessage } from '../messaging/actions';
import { IApiErrorResponse } from '@llws/api-common';

export interface IStatementGenerationInput {
  accountId: number,
  statementStartDate: string,
  statementType: EStatementType
  ownershipGroupId?: number
}

export interface IGenerateStatementActionResponse {
  actionResponse: IStatement[]
}

export type TStatementsQueryOptions = {
  statementIdFilter?: Nullable<string>;
  ownershipGroupId: Nullable<number>,
  clientIds: Nullable<number[]>
  statementStatuses?: Nullable<string[]>,
  statementGenerationTypes?: Nullable<string[]>,
  startDate?: Nullable<string>,
  endDate?: Nullable<string>,
  page?: number;
  pageSize?: number;
  sortCriteria?: string;
  sortDirection?: 'ASC' | 'DESC';
  sortAlias?: string;
}

export const loadSingleStatementViewData = async (id: number) => {
  dispatch(setLoadedState('loading'));
  const url = `${REACT_APP_API_ROOT_URI}/statements/singleView/${id}`;
  const response = await typedApiFetch<IApiQuerySingularResponse<ISingleViewStatementData>>(url);
  const responseData = await response.json();
  dispatch(singleViewStatementDataReceived(responseData));
  dispatch(setLoadedState('loaded'));

};

export const generateStatements = async ({
  statementsToGenerate,
}: {
  statementsToGenerate:IStatementGenerationInput[],
}) => {
  dispatch(setLoadedState('loading'));
  const url = `${REACT_APP_API_ROOT_URI}/actions/`;
  const body = {
    action: `generateStatements`,
    parameters: {
      statementsToGenerate,
    },
  };
  const response = await typedApiFetch<IApiQuerySingularResponse<IGenerateStatementActionResponse>>(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const responseData = await response.json();

  const showMessageContent: IShowMessageProps = (
    response.ok 
      ? {
        message: `Statements successfully generated.`,
        severity: 'success', 
      }
      : { 
        message: `Failed to generate statements.`, 
        severity: 'error', 
      }
  );
  try{

    showMessage({...showMessageContent});
    dispatch(statementListReceived(responseData));
    dispatch(setLoadedState('loaded'));
    return first(responseData.data.actionResponse);
  } catch (error) {
    showMessage({
      message: error instanceof Error ? error.message : `Failed to generate statement.`,
      severity: 'error', 
    });
    console.error(error);
    dispatch(setLoadedState('loaded'));
  }

};

