import { floatVal } from "@jamesgmarks/utilities";
import { Link } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { Button, FormCheck } from "react-bootstrap";
import Table from "react-bootstrap/esm/Table";
import { useAuth } from "../../../customHooks/useAuth";
import { loadCommissions, markCommissionsAsPaid } from "../../../redux/features/commissions/actions";
import { ICommissionRecord } from "../../../redux/features/commissions/interfaces";
import { useAppSelector } from "../../../redux/hooks";
import { DataTable } from "../../parts/data-table/DataTable";
import { DownloadCSV } from "../../parts/DownloadCSV";
import { LoadingSpinner } from "../../parts/LoadingSpinner";

const CommissionsRecordListDataRow = ({ commissionRecord, checked, setSelected }: {
  commissionRecord: ICommissionRecord,
  checked: boolean,
  setSelected: ((newValue: ICommissionRecord, selected: boolean) => void),
  showCheckboxes: boolean
}) => {

  return (
    <tr>

      <td className="invoices-checkbox-column" style={{ textAlign: "center" }}>
        {/* <FormCheck checked={checked} onChange={(e) => (invoice.id && setSelected(invoice.id!, e.target.checked))} /> */}
        {
          (commissionRecord.source === 'hydra')
          && <FormCheck
            checked={checked}
            onChange={(e) => (commissionRecord && setSelected(commissionRecord, e.target.checked))}
          />
        }
      </td>
      <td>{commissionRecord.source}</td>
      <td>{commissionRecord.commissionPayDate}</td>
      <td>{commissionRecord.dateInvoiced}</td>
      <td>{commissionRecord.invoiceNumber}</td>
      <td>{
        ([...(commissionRecord.clientSubscriptions ?? []), ...(commissionRecord.credits ?? []) ])
          .map((cs) => `(${cs?.id}) ${cs?.invoiceDescription}`)
      }</td>
      <td>{commissionRecord.invoiceState}</td>
      <td>{`$${commissionRecord.outstanding}`}</td>
      <td>{commissionRecord.clientName}</td>
      {/* <td>{commissionPayable.notes}</td> */}
      {/* <td>{subscriptions}</td> */}
      {/* <td>{nonRecurringClientSubscriptions}</td> */}
      <td>{commissionRecord.total + ' ' + (commissionRecord.currency === 1 ? 'CAD' : 'USD')}</td>
      <td>{commissionRecord.cadTotal}</td>
    </tr>
  );
};

export const CommissionsTable = ({
  commissionsList, tab, commissionSource, salesRepId, invoiceStates, showCommissionRates = false, 
}: {
  commissionsList: ICommissionRecord[],
  tab?: String,
  commissionSource?: String
  showCommissionRates?: boolean
  salesRepId?: number,
  invoiceStates?: string
}) => {
  const commissionsTotal = commissionsList.reduce((acc, curr) => acc + floatVal(curr.cadTotal), 0);
  const commissionsLoadedState = useAppSelector(state => state.commissions.loadedState);

  const { permissions } = useAuth();
  // TODO: Use `hasPermission`
  const [
    updateCommissionPaidStatusPermission,
    // setupdateCommissionPaidStatusPermission,
  ] = useState((permissions.FULL_ACCESS === 'grant' || permissions.UPDATE_COMMISSION_PAID_STATUS === 'grant'));

  const showCheckboxes = (tab === 'payable' && (commissionSource !== 'legacy') && updateCommissionPaidStatusPermission);
  const [selectedCommissions, setSelectedCommissions] = useState<ICommissionRecord[]>([]);
  const [allChecked, setAllChecked] = useState(false as boolean);

  useEffect(() => {
    (
      allChecked
        ? setSelectedCommissions([
          ...selectedCommissions,
          ...commissionsList.filter((cl) => !selectedCommissions.includes(cl) && (cl.source === 'hydra')),
        ])
        : setSelectedCommissions([])
    );
  }, [ allChecked ]); // eslint-disable-line react-hooks/exhaustive-deps

  const setSelected = (value: ICommissionRecord, selected: boolean) => {
    (
      selected
        ? setSelectedCommissions([
          ...selectedCommissions,
          ...[value],
        ])
        : setSelectedCommissions([
          ...selectedCommissions.filter((sc) => sc !== value),
        ])
    );
  };

  return (<>
    {
      commissionsLoadedState === 'loading'
        ? <LoadingSpinner loadedState={commissionsLoadedState}></LoadingSpinner>
        : <>
          <br></br>
          {showCheckboxes && <Button onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            if (confirm(`Are you sure you want to mark the selected records as paid.`)) {
              markCommissionsAsPaid(selectedCommissions, () => {
                loadCommissions(
                  salesRepId || 0,
                  `/payable?invoiceStates=${invoiceStates}&paid=false&unpaid=true&source=${commissionSource}`,
                );
              });
            }
          }}>Mark checked records as paid</Button>}

          <DataTable
            data={(commissionsList || []).slice().sort((a, b) => b.cadTotal - a.cadTotal)}
            downloadFilename='commissions'
            downloadHeaders={[
              'ids',
              'genSubId',
              'type',
              'source',
              'commissionPayDate',
              'dateInvoiced',
              'invoiceNumber',
              'subscription',
              'invoiceState',
              'outstanding',
              'client',
              'currency',
              'exchangeRate',
              ...(showCommissionRates ? ['rate'] : []),
              'total',
              'cadTotal',
            ]
            }
            downloadAdaptor={(commissionRecord) => [
              commissionRecord.ids,
              commissionRecord.genSubId,
              commissionRecord.type,
              commissionRecord.source,
              commissionRecord.commissionPayDate,
              commissionRecord.dateInvoiced,
              commissionRecord.invoiceNumber,
              [
                ...commissionRecord.clientSubscriptions,
                ...commissionRecord.credits,
              ]?.map((cs) => `(${cs?.id}) ${cs?.invoiceDescription}`),
              commissionRecord.invoiceState,
              commissionRecord.outstanding,
              commissionRecord.clientName,
              commissionRecord.currency,
              commissionRecord.exchangeRate,
              ...(showCommissionRates ? [commissionRecord.commissionRate] : []),
              commissionRecord.total + ' ' + (commissionRecord.currency === 1 ? 'CAD' : 'USD'),
              commissionRecord.cadTotal,
            ].map((commissionRecord) => (commissionRecord ?? '').toString())}
            displayHeaders={[
              <span className="invoices-checkbox-column" style={{ textAlign: "center" }}>
                {
                  showCheckboxes
                  && <FormCheck checked={allChecked} onChange={(e) => (setAllChecked(e.target.checked))} />
                }
              </span>,
              'Source',
              'Commission Pay Date',
              'Date Invoiced',
              'Invoice Number',
              'Subscription',
              'Invoice State',
              'Outstanding',
              'Client',
              ...(showCommissionRates ? ['Rate'] : []),
              'Total',
              'Total(CAD)',
            ]
            }
            displayAdaptor={(commissionRecord) => [
              <span className="invoices-checkbox-column" style={{ textAlign: "center" }}>
                {/* <FormCheck checked={checked} onChange={(e) => (invoice.id && setSelected(invoice.id!, e.target.checked))} /> */}
                {(commissionRecord.source === 'hydra')
                  && <FormCheck
                    checked={
                      selectedCommissions.find(
                        (sc) => sc.ids === commissionRecord.ids && sc.type === commissionRecord.type,
                      )
                        ? true
                        : false
                    }
                    onChange={(e) => (commissionRecord && setSelected(commissionRecord, e.target.checked))} />}
              </span>,
              commissionRecord.source,
              commissionRecord.commissionPayDate,
              commissionRecord.dateInvoiced,
              <Link href={`/invoices/${commissionRecord.invoiceId}`} target="_blank">
                {commissionRecord.invoiceNumber}
              </Link>,
              [
                ...commissionRecord.clientSubscriptions,
                ...commissionRecord.credits,
              ]?.map((cs) => `(${cs?.id}) ${cs?.invoiceDescription}`),
              commissionRecord.invoiceState,
              commissionRecord.outstanding,
              commissionRecord.clientName,
              ...(showCommissionRates ? [`${floatVal(commissionRecord.commissionRate) * 100}%`] : []),
              commissionRecord.total + ' ' + (commissionRecord.currency === 1 ? 'CAD' : 'USD'),
              commissionRecord.cadTotal,
            ] as (ReactElement | string)[]}
            footerData={[
              'Total',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              ...(showCommissionRates ? [``] : []),
              '',
              `${commissionsTotal}`,
            ]}
          />

          {false && <DownloadCSV>
            <Table striped bordered hover variant="dark" size="sm">
              <thead>
                <tr>
                  <th className="invoices-checkbox-column" style={{ textAlign: "center" }}>
                    {
                      showCheckboxes
                      && <FormCheck checked={allChecked} onChange={(e) => (setAllChecked(e.target.checked))} />
                    }
                  </th>
                  <th>Source</th>
                  <th>Commission Pay Date</th>
                  <th>Date Invoiced</th>
                  <th>Invoice Number</th>
                  <th>Subscription</th>
                  <th>Invoice State</th>
                  <th>Outstanding</th>
                  <th>Client</th>
                  {/* <th>Notes </th> */}
                  {/* <th>Subscriptions</th> */}
                  {/* <th>Fees</th> */}
                  <th>Total</th>
                  <th>Total(CAD)</th>
                </tr>
              </thead>
              <tbody>
                {(commissionsList || []).slice().sort((a, b) => b.cadTotal - a.cadTotal)
                  .map(
                    (c: ICommissionRecord, k) => <CommissionsRecordListDataRow
                      key={k} commissionRecord={c}
                      checked={selectedCommissions.find((sc) => sc.ids === c.ids && sc.type === c.type) ? true : false}
                      setSelected={setSelected}
                      showCheckboxes={showCheckboxes}
                    />,
                  )}
              </tbody>
              <tfoot>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  {/* <td></td> */}
                  <td></td>
                  <td></td>
                  <td>{commissionsTotal}</td>
                </tr>
              </tfoot>
            </Table>
          </DownloadCSV>}
        </>
    }
  </>);
};