import React from "react";
import { Route, Switch } from "react-router-dom";
import { IPartners } from '@llws/typeorm-entities/dist/interfaces/lift';
import { PartnersListView } from './PartnersListView';
import { useAppSelector } from "../../../redux/hooks";
import { def } from "@jamesgmarks/utilities";
import { loadPartners } from "../../../redux/features/partners/actions";

export const PartnersContainer = () => {
  const partners: IPartners[] = useAppSelector(state => state.partners.partnersList);
  const loadState: string = useAppSelector(state => state.partners.loadedState);

  if(!def(partners) || (partners.length === 0 && loadState !== 'loaded')) {
    console.log('attempting to load partners');
    loadPartners();
  }

  return (
    <>
      <header className="Section-header">Partners</header>
      <Switch>
        {/* <Route path="/partners/create"><CreatePartner /></Route> */}
        <Route path="/partners"><PartnersListView partners={(partners || [])} /></Route>
      </Switch>
    </>
  );
};
