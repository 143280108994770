import { EHydraCreditNoteState, EHydraCreditNoteVoidState } from "../entity-interfaces/EHydraCreditNoteState";
import { IBillingAddress } from "../../../rentsync-api/invoicing/IBillingAddress";
import { ICredit } from "./ICredit";
import { Nullable, PartialOn } from "@jamesgmarks/utilities";
import { Payment, PdfGeneration } from "../../../entities/hydra";
import { IBillingAccount } from "../entity-interfaces/IBillingAccount";
import { IOwnershipGroups } from "@llws/typeorm-entities/dist/interfaces/lift";
import { INoteComment } from "../../../entities/jsonTypes/INoteComment";

export interface ICreditNote {
  readonly id?: number;
  billingAccountId?: number;
  billingAddress: Nullable<IBillingAddress>;
  clientId?:number,
  credits: ICredit[];
  currencyCode: string;
  freshbooksCreditNoteId?: Nullable<number>;
  noteDate: string;
  noteNumber?: string,
  notes: Nullable<INoteComment[]>;
  ownershipGroupId?: Nullable<number>;
  pdfGenerations: PdfGeneration[];
  state: EHydraCreditNoteState;
  voidState: Nullable<EHydraCreditNoteVoidState>;
  voidRequestReason: Nullable<string>;
  voidApproveOrDenyReason: Nullable<string>;
  subtotalAmount: Nullable<string>;
  taxAmount: Nullable<string>;
  taxName: Nullable<string>;
  taxRate: Nullable<string>;
}

export interface IAugmentedCreditNote extends ICreditNote {
  id: number;
  billingAccount: IBillingAccount;
  ownershipGroup?: IOwnershipGroups;
}

export interface ICreditNoteWithPayments extends ICreditNote {
  payments: Payment[];
}

export type TCreditNoteRequiredProperties = (
  'id' | 'noteNumber' | 'billingAccountId' | 'currencyCode' | 'state' | 'noteDate' | 'credits'
);
export type TNewCreditNote = PartialOn<ICreditNote, TCreditNoteRequiredProperties>;

/** Type guard for whether an object is a Credit Note. */
export const isICreditNote = (object: unknown): object is ICreditNote => !!(object as ICreditNote).noteDate;

/** Type guard for whether an array contains only `ICreditNote`s. */
export const isICreditNoteArray = (object: unknown[]): object is ICreditNote[] => object.every(isICreditNote);

export const assertICreditNoteArray = (arr: unknown[]): ICreditNote[] | never => {
  if (!isICreditNoteArray(arr)) {
    throw new Error('This is not an array of `ICreditNote`!');
  }

  return arr;
};

/** Type guard for whether an object is a Credit Note. */
export const isIAugmentedCreditNote = (object: unknown): object is IAugmentedCreditNote => (
  !!(object as ICreditNote).noteDate  && !!(object as IAugmentedCreditNote).billingAccount
);

/** Type guard for whether an array contains only `IAugmentedCreditNote`s. */
export const isIAugmentedCreditNoteArray = (object: unknown[]): object is IAugmentedCreditNote[] => (
  object.every(isIAugmentedCreditNote)
);

export const assertIAugmentedCreditNoteArray = (arr: unknown[]): IAugmentedCreditNote[] | never => {
  if (!isIAugmentedCreditNoteArray(arr)) {
    throw new Error('This is not an array of `IAugmentedCreditNote`!');
  }

  return arr;
};
