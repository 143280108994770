import { IApiCommandResponse, IApiQueryResponse } from '@llws/api-common';
import {
  IBuildings,
  IClients,
} from '@llws/typeorm-entities/dist/interfaces/lift';
import IClient from '../../../../../freshbooks-api/models/Client';
import { store, dispatch } from '../../store';
import {
  clearClientBillingContactList,
  clearClientOwnershipGroupsList,
  clientBillingContactDataReceived,
  ClientBillingTypesSummary,
  clientOwnershipGroupsDataReceived,
  clientsDropDownListReceived,
  clientsListReceived,
  currentClientBillingAccountReceived,
  currentClientBillingAccountRecentInvoicesReceived,
  currentClientBillingTypeSummaryReceived,
  currentClientBuildingTypeHistoryReceived,
  currentClientPropertiesReceived,
  currentClientReceived,
  currentHydraClientReceived,
  EClientLoadState,
  EClientMigrateState,
  IRecentInvoice,
  isLastMonthLockedReceived,
  moreClientsReceived,
  setClientsFilters,
  setLoadedState,
  setMigratingState,
} from './clientsSlice';
import { arrayWrap, compactArray, keys, Nullable } from '@jamesgmarks/utilities';
import { ClientHasBillingContacts } from '@llws/typeorm-entities';
import { apiFetch, IApiQueryListResponse, IApiQuerySingularResponse, typedApiFetch } from '../../utils';
import { IClientMatch } from '../../../../../rentsync-api/IClientMatch';
import { IClientDropdownItem } from '../../../entity-interfaces/IClientDropdownItem';
import { IShowMessageProps, showMessage } from '../messaging/actions';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { makeYmd } from '../../../app-utils';
import { IOwnershipGroupDropdownItem } from '../../../entity-interfaces/IOwnershipGroupDropdownItem';
import { BillingProfile, LiftClient } from '../../../../../entities/hydra';
import { IBillingAccount } from '../../../entity-interfaces/IBillingAccount';

const getFilterQueryStringItems = () => {
  const filters = store.getState().clients.filters || {};
  const queryString = filters ? `?${keys(filters).map(f => `${f}=${filters[f]}`).join('&')}` : '';
  return queryString;
};

export const migrateUnmatchedClients = async () => {
  const url = `${REACT_APP_API_ROOT_URI}/actions/`;
  const body = {
    action: 'importMissingLiftClients',
    parameters: {},
  };
  const response = await apiFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const responseData = await response.json() as IApiQueryResponse<IClient>;
  await loadClients();
  return responseData;
};

export const loadDropDownClients = async (showPartners: boolean) => {
  const url = `${REACT_APP_API_ROOT_URI}/rentsync/clientsDropdownList${showPartners ? '?showPartners=1' : ''}`;
  // console.log(`Loading clients.`, { url });
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<IClientDropdownItem>;
  // console.log({ status: response.status, responseData });
  dispatch(clientsDropDownListReceived(responseData)); // TODO: Error handling?
};

export const loadClients = async () => {
  dispatch(setLoadedState(EClientLoadState.loading));

  const queryString = getFilterQueryStringItems();
  const url = `${REACT_APP_API_ROOT_URI}/rentsync/clients${queryString}`;
  // console.log(`Loading clients.`, { url });
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<IClientMatch>;
  // console.log({ status: response.status, responseData });
  dispatch(clientsListReceived(responseData ?? [])); // TODO: Error handling?

  dispatch(setLoadedState(EClientLoadState.loaded));
};

export const loadCurrentClient = async (
  { clientId, forceRefresh = false }: { clientId: number, forceRefresh: boolean },
) => {
  const currentClient = store.getState().clients.currentClient;
  if (!forceRefresh && clientId === currentClient?.id) {
    // console.log(`Client ${clientId} already loaded.`);
    return;
  }
  const url = `${REACT_APP_API_ROOT_URI}/rentsync/clients/${clientId}`;
  // console.log(`Loading current client.`, { url });
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<IClients>;
  // console.log({ status: response.status, responseData });
  dispatch(currentClientReceived(responseData)); // TODO: Error handling?
  return responseData.data as IClients;
};

export const loadCurrentHydraClient = async (
  clientId: string,
) => {
  const url = `${REACT_APP_API_ROOT_URI}/clients/${clientId}`;
  const response = await typedApiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<LiftClient>;
  dispatch(currentHydraClientReceived(responseData));
};

export const loadMoreClients = async () => {
  const queryString = getFilterQueryStringItems();
  const url = `${REACT_APP_API_ROOT_URI}${store.getState().clients.nextPage}${queryString}`;
  console.log(`Loading clients.`, { url });
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<IClientMatch>;
  console.log({ status: response.status, responseData });
  dispatch(moreClientsReceived(responseData)); // TODO: Error handling?
};

export const setClientFilter = <T, >(key: string, value: T) => {
  const filter = { [key]: value };
  // console.info({ filter });
  dispatch(setClientsFilters(filter));
};

export const loadClientBillingContacts = async ({
  freshbooksClientId,
  searchFilter = '',
  ownershipGroupId = null,
} : {
  freshbooksClientId: Nullable<number>
  searchFilter?: string,
  ownershipGroupId?: Nullable<number>
}) => {
  if (freshbooksClientId === null) {
    return dispatch(clearClientBillingContactList());
  }
  const searchFilterQuery = `searchFilter=${searchFilter}`;
  const ownershipGroupIdQuery = `ownershipGroupId=${ownershipGroupId ?? ''}`;
  const freshbooksClientIdQuery = `freshbooksClientId=${freshbooksClientId}`;
  const queryString = `?${compactArray([freshbooksClientIdQuery, ownershipGroupIdQuery, searchFilterQuery]).join('&')}`;
  const url = `${REACT_APP_API_ROOT_URI}/billing_contacts/client_has_billing_contacts${queryString}`;
  const response = await typedApiFetch<IApiQueryResponse<ClientHasBillingContacts>>(url);
  const responseData = await response.json();
  dispatch(clientBillingContactDataReceived(responseData)); // TODO: Error handling?
};

export const saveClientBillingContact = async ({
  freshbooksClientId,
  billingContactId,
  ownershipGroupId = null,
  receivesInvoices = 0,
} : {
  freshbooksClientId: Nullable<number>
  billingContactId: number,
  ownershipGroupId?: Nullable<number>
  receivesInvoices?: number
}) => {
  
  dispatch(setLoadedState(EClientLoadState.loading));

  const body = {
    freshbooksClientId,
    billingContactId,
    ownershipGroupId,
    receivesInvoices,
  };
  const url = (
    `${REACT_APP_API_ROOT_URI}/billing_contacts/client_has_billing_contacts/${freshbooksClientId}/${billingContactId}`
  );
  const response = await typedApiFetch<IApiCommandResponse<ClientHasBillingContacts>>(
    url,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    },
  );

  const showMessageContent: IShowMessageProps = (
    response.ok 
      ? {
        message: `Billing contact has successfully been added to the client.`,
        severity: 'success', 
      }
      : { 
        message: `Failed to add the billing contact.`, 
        severity: 'error', 
      }
  );

  showMessage({...showMessageContent});
  dispatch(setLoadedState(EClientLoadState.loaded));
};

export const loadClientOwnershipGroupsDropdown = async (freshbooksClientId: Nullable<number>) => {
  if (freshbooksClientId === null) {
    return dispatch(clearClientOwnershipGroupsList());
  }
  const url = `${REACT_APP_API_ROOT_URI}/rentsync/ownership_groups?freshbooksClientId=${freshbooksClientId}`;
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryResponse<IOwnershipGroupDropdownItem>;
  // console.info({ status: response.status, responseData });
  dispatch(clientOwnershipGroupsDataReceived(responseData)); // TODO: Error handling?
};

export const getIsLastMonthLocked = async () => {
  const url = `${REACT_APP_API_ROOT_URI}/clients/is_last_month_locked`;

  const response = await apiFetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const responseData = await response.json() as IApiQueryResponse<boolean>;
  console.log(responseData);
  dispatch(isLastMonthLockedReceived(responseData));
};

/**
 * Executes an action handler to regenerate monthly invoices for a given `freshbooksClientId`, along with notes re: the reason for the run.
 * Displays a success snackbar message for the user, indicating the action is running.
 * @param year - Year for which to regenerate invoices.
 * @param month - Month for which to regenerate invoices.
 * @param freshbooksClientId - For the client to whom invoices belong.
 * @param notes - Explanation of reason for manually regenerating client's invoices.
 */
export const sendClientRerunRequest = async (
  year: number,
  month: number,
  freshbooksClientId: number,
  notes: string,
  asJob = false,
) => {
  const body = {
    action: "regenerateMonthlyInvoicesForClient",
    parameters: {
      year,
      month,
      freshbooksClientIds: [
        freshbooksClientId,
      ],
      notes,
      asJob,
    },
  };
  const url = `${REACT_APP_API_ROOT_URI}/actions`;
  const response = await typedApiFetch<IApiCommandResponse<
  { actionResponse: unknown }
  >>(
    url,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
  );
  const responseData = await response.json();
  console.log({ status: response.status, response: responseData.data });
  showMessage({ message: `${arrayWrap(responseData.data)[0].actionResponse}`, severity: 'success' });
};

const loadClientProperties = async ({
  clientId,
}: {
  clientId: number,
}) => {
  const url = `${REACT_APP_API_ROOT_URI}/clients/${clientId}/properties`;
  console.log(`Loading client properties.`, { url });
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryListResponse<IBuildings>;
  console.log({ status: response.status, responseData });
  dispatch(currentClientPropertiesReceived(responseData)); // TODO: Error handling?
  return responseData.data as IBuildings[];
};

const loadClientBillingTypeSummary = async ({
  clientId,
}: {
  clientId: number,
}) => {
  const url = `${REACT_APP_API_ROOT_URI}/clients/${clientId}/billing_type_defaults`;
  console.log(`Loading client properties.`, { url });
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQuerySingularResponse<ClientBillingTypesSummary>;
  console.log({ status: response.status, responseData });
  dispatch(currentClientBillingTypeSummaryReceived(responseData)); // TODO: Error handling?
  return responseData.data as ClientBillingTypesSummary;
};

const loadClientBillingTypeHistory = async ({
  clientId,
}: {
  clientId: number,
}) => {
  const url = `${REACT_APP_API_ROOT_URI}/clients/${clientId}/billing_type_history`;
  console.log(`Loading client properties.`, { url });
  const response = await apiFetch(url);
  const responseData = await response.json() as IApiQueryListResponse<IBuildings>;
  console.log({ status: response.status, responseData });
  dispatch(currentClientBuildingTypeHistoryReceived(responseData)); // TODO: Error handling?
  return responseData.data;
};

export const updateClientBillingTypeHistories = async ({
  clientId,
  propertyIds,
  billingTypeId,
  startDate,
}: {
  clientId: number,
  propertyIds: number[],
  billingTypeId: number,
  startDate: Date,
}) => {
  const url = `${REACT_APP_API_ROOT_URI}/clients/${clientId}/billing_type_history`;
  console.log(`Loading client properties.`, { url });
  const response = await apiFetch(url, {
    method: 'POST',
    body: JSON.stringify({ clientId, propertyIds, billingTypeId, startDate: makeYmd(startDate, true) }),
  });
  const responseData = await response.json() as IApiQueryListResponse<IBuildings>;
  console.log({ status: response.status, responseData });
  // dispatch(currentClientBuildingTypeHistoryReceived(responseData)); // TODO: Error handling?
  return responseData.data;
};

export const loadClientBillingTypeData = async (
  clientId: number,
) => {
  await Promise.all([
    loadCurrentClient({ clientId, forceRefresh: true }),
    loadClientProperties({ clientId }),
    loadClientBillingTypeSummary({ clientId }),
    loadClientBillingTypeHistory({ clientId }),
  ]);
};

type BillingProfileUpdateFields = {
  id: number,
  accountId: number,
  organization: string,
  email: string,
  street1: string,
  street2: string,
  city: string,
  province: string,
  country: string,
  code: string,
  contactPhone: string,
  contactName: string,
}

export const updateBillingProfile = async (newBillingProfileInformation: BillingProfileUpdateFields) => {
  const url = `${REACT_APP_API_ROOT_URI}/accounts/${newBillingProfileInformation.accountId}/billing_profile`;
  const response = await typedApiFetch(
    url,
    {
      method: 'PUT',
      body: JSON.stringify(newBillingProfileInformation),
    },
  );

  const responseData = await response.json() as IApiCommandResponse<BillingProfile>;

  return responseData.data;
};

/** Fetches a specified Billing Account, along with its clients. */
export const loadBillingAccountWithClients = async (
  accountId: number,
): Promise<IBillingAccount> => {
  const url = `${REACT_APP_API_ROOT_URI}/accounts/${accountId}`;

  const response = await typedApiFetch<IApiQuerySingularResponse<IBillingAccount>>(url);

  const { data: billingAccount } = await response.json();

  dispatch(currentClientBillingAccountReceived(billingAccount));

  return billingAccount;
};

/** Fetches the most-recent invoices (by invoice date) belonging to a Billing Account. */
export const loadBillingAccountRecentInvoices = async (
  billingAccountId: number,
  count: number,
): Promise<IRecentInvoice[]> => {
  const url = `${REACT_APP_API_ROOT_URI}/invoices/recent/${billingAccountId}/${count}`;

  const response = await typedApiFetch<IApiQueryListResponse<IRecentInvoice>>(url);

  const recentInvoices = (
    arrayWrap(
      (await response.json()).data,
    )
  );

  dispatch(currentClientBillingAccountRecentInvoicesReceived(recentInvoices));

  return recentInvoices;
};

export const migrateNewContactsToXero = async (
  {loadClientList}: {loadClientList: boolean} = {loadClientList: false},
) => {
  dispatch(setMigratingState(EClientMigrateState.migrating));
  const url = `${REACT_APP_API_ROOT_URI}/clients/migrate_clients_to_xero`;
  const response = await apiFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const responseData = await response.json() as IApiQuerySingularResponse<{migrationStatus: string}>;
  if (loadClientList) {
    await loadClients();
  }
  if (responseData.data.migrationStatus !== 'success') {
    showMessage({
      message: `Failed to migrate clients to xero`,
      severity: 'error',
    });
  }
  showMessage({
    message: `Clients successfully migrated to xero`,
    severity: 'success',
  });
  dispatch(setMigratingState(EClientMigrateState.migrated));
  return responseData;
};