import { IOwnershipGroups } from '@llws/typeorm-entities/dist/interfaces/lift';
import { createSlice } from '@reduxjs/toolkit';
import { IActionType } from '../../utils';

export interface IOwnershipGroupsState {
  ownershipGroups: IOwnershipGroups[],
};

export const ownershipGroupsSlice = createSlice({
  name: 'ownershipGroupsSlice',
  initialState: {
    ownershipGroups: [],
  } as IOwnershipGroupsState,
  reducers: {
    ownershipGroupsListReceived: (state, action: IActionType<IOwnershipGroups[]>) => {
      // console.log('setting ownership groups to: ', { ownershipGroups: action.payload })
      state.ownershipGroups = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  ownershipGroupsListReceived,
} = ownershipGroupsSlice.actions;

export default ownershipGroupsSlice.reducer;