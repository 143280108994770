import React, {
  ChangeEvent, MouseEvent, TouchEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  FormCheck,
  DropdownButton,
  Dropdown,
  ButtonGroup,
} from 'react-bootstrap';

import {
  clamp,
  compactObject,
  floatVal,
  Hash,
  HashOf,
  hasKey,
  intVal,
  keys,
  Nullable,
} from '@jamesgmarks/utilities';

import { dateFromYmd, makeYmd, roundFloatToCents, subtractDays, ucaseFirst } from '../../../app-utils';
import { DownloadCSV } from '../../parts/DownloadCSV';
import { DropdownMultiselect } from '../../parts/DropdownMultiselect';
import { IClientDropdownItem } from '../../../entity-interfaces/IClientDropdownItem';
import { EHydraState, IInvoice } from '../../../entity-interfaces/IInvoice';
import { loadInvoices, publishInvoices, triggerInvoicePdfGeneration } from '../../../redux/features/invoices/actions';
import pdfFileIcon from '../../../assets/images/pdf.png';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { SendDocumentsModal } from './SendDocumentsModal';
import { StyledTableCell, StyledTableRow } from '../../parts/mui/StyledTables';
import { typedApiFetch } from '../../../redux/utils';
import { useAppSelector } from '../../../redux/hooks';
import { OwnershipGroupDropdown } from '../../parts/OwnershipGroupDropdown';
import { EHydraPaymentType } from '../../../redux/features/payments/interfaces';
import { StatusDropdown } from '../../parts/statusDropdown/StatusDropdown';
import {
  invoiceStatusCustomOptions,
  invoiceStatusFilterOptions,
} from '../../parts/statusDropdown/StatusOptions';
import { DateRangeDropdown } from '../../parts/DateRangeDropdown';
import { getEndOfDay } from '../../../app-utils/helpers';
import { ClientMultiSelectDropdown } from 'src/components/parts/ClientMultiSelectDropdown';
import { arrangeLinesForInvoice } from 'src/app-utils/invoice-lines';
import { HHMultiStateToggle } from 'src/components/parts/HHMultiStateToggle';

interface InvoiceListDataRowProps {
  checked: boolean,
  invoice: IInvoice,
  setSelected: ((invoiceId: number, selected: boolean) => void),
}

const reducedSubtotalLine = ({
  reductionDescription,
  amountReduced,
  invoiceAmount,
}:{
  reductionDescription:string,
  amountReduced: number,
  invoiceAmount:string}) => {
  return <>
    <br /><small style={{ whiteSpace: 'nowrap' }}>{reductionDescription}: (${amountReduced.toFixed(2)})</small>
    <br />
    <small style={{ whiteSpace: 'nowrap' }}>
      Total: ${(floatVal(invoiceAmount) - amountReduced).toFixed(2)}
    </small>
  </>;
};

const InvoiceListDataRow = ({
  checked,
  invoice,
  setSelected,
}: InvoiceListDataRowProps) => {
  const hasDownload = (invoice.pdfGenerations ?? []).length > 0;
  const og = invoice.ownershipGroup?.name ?? invoice.ownershipGroupId;
  const creditedAmount = floatVal((invoice.creditsApplied ?? []).reduce((acc, c) => acc + floatVal(c.amount), 0));
  const creditNoteAmount = (
    floatVal(
      (invoice.payments ?? [])
        .filter((p) => p.paymentType === EHydraPaymentType.creditBalance)
        .reduce((acc, c) => acc + floatVal(c.amount), 0),
    )
  );
  const [viewButtonColor, setViewButtonColor] = useState<'primary' | 'secondary' | 'info'>('primary');
  const theme = useTheme();

  return (
    <StyledTableRow
      customSx={{ userSelect: 'none' }}
      dark={true}
      hover={true}
      onMouseOver={(e: MouseEvent | TouchEvent) => setViewButtonColor('secondary')}
      onMouseLeave={(e: MouseEvent | TouchEvent) => setViewButtonColor('primary')}
    >
      <StyledTableCell
        dark={true}
        className="invoices-checkbox-column"
        style={{ textAlign: 'center' }}
        onClick={() => setSelected(invoice.id, !checked)}>
        {
          !(invoice.hydraState === 'legacy-imported')
            ? <FormCheck
              checked={checked}
              onChange={(e) => (invoice.id && setSelected(invoice.id!, e.target.checked))}
            />
            : 'N/A'
        }
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }} onClick={() => setSelected(invoice.id, !checked)}>
        {invoice.invoiceNumber ?? `PV:${invoice.id}`}
        &nbsp;
        <img
          src={pdfFileIcon}
          alt="pdf"
          height="20px"
          style={{
            filter: 'grayscale(' + (hasDownload ? '0' : '1') + ')',
          }}
          title={hasDownload ? 'Download available' : 'Download not available'}
        />
      </StyledTableCell>
      <StyledTableCell
        dark={true}
        onClick={() => setSelected(invoice.id, !checked)}
        style={{ textAlign: 'left' }}
      >
        {(invoice.billingAccount?.clients ?? []).length === 1
          && (invoice.billingAccount?.clients ?? []).map(c => c.clientName).join(', ')}
        {(invoice.billingAccount?.clients ?? []).length > 1 && (<>
          {(invoice.billingAccount?.clients ?? []).filter(c => c.isParentClient).map(c => c.clientName).join(', ')}
          {` (${(invoice.billingAccount?.clients ?? []).length} total clients)`}
        </>)
        }
        {(invoice.billingAccount?.partners ?? []).length === 1
          && (invoice.billingAccount?.partners ?? []).map(p => `${p.partnerName}`).join(', ')}
        {(invoice.billingAccount?.partners ?? []).length > 1 && 'Multiple Partners'}
        {og && <><br /><small>{og}</small></>}
      </StyledTableCell>
      <StyledTableCell dark={true} onClick={() => setSelected(invoice.id, !checked)} style={{}}>
        {
          invoice.lines ? arrangeLinesForInvoice(invoice.lines)[0]?.description ?? '' : ''
        }
      </StyledTableCell>
      <StyledTableCell dark={true} onClick={() => setSelected(invoice.id, !checked)} style={{ whiteSpace: 'nowrap' }}>
        {makeYmd(dateFromYmd(invoice.invoiceDate.split('T')[0]), true)}
      </StyledTableCell>
      <StyledTableCell dark={true} onClick={() => setSelected(invoice.id, !checked)}>
        <span
          style={{ whiteSpace: 'nowrap' }}
        >
          ${(floatVal(invoice.amountInvoiced)).toFixed(2)} {invoice.currency}
        </span>
        {
          creditedAmount > 0
          && reducedSubtotalLine({
            reductionDescription: 'Credit',
            amountReduced: creditedAmount,
            invoiceAmount: invoice.amountInvoiced,
          })
        }
        {
          creditNoteAmount > 0
          && reducedSubtotalLine({
            reductionDescription: 'Credit Note',
            amountReduced: creditNoteAmount,
            invoiceAmount: invoice.amountInvoiced,
          })
        }
      </StyledTableCell>
      {/* Possibly helpful for developers only */}
      {/* <td>{invoice.runId}</td> */}
      {/* <td>{invoice.generationType}</td> */}
      <StyledTableCell dark={true} onClick={() => setSelected(invoice.id, !checked)}>
        {
          invoice.thirdPartyInvoiceId
            ? `${invoice.freshbooksState}`
            : `${invoice.hydraState.replace('sent', 'published')}`
        }
      </StyledTableCell>
      {/* <StyledTableCell dark={true} onClick={() => setSelected(invoice.id, !checked)}>
        {
          (
            invoice.invoiceSendHistory.find((sendHistory) => !sendHistory.sentTime)
            && <>SENDING... <CircularProgress size={20} color='secondary' sx={{ml: 2, mb: -0.65}} /></>
          )
          || (
            invoice.thirdPartyInvoiceId
              ? `${invoice.freshbooksState}`
              : `${invoice.hydraState.replace('sent', 'published')}`
          )
        }
      </StyledTableCell> */}
      <StyledTableCell dark={true} onClick={() => setSelected(invoice.id, !checked)}>
        {invoice.generationType === 'manual' ? 'OTF' : 'Monthly'}
      </StyledTableCell>
      <StyledTableCell dark={true}>
        <Dropdown as={ButtonGroup} size="sm">
          <Button
            href={`/invoices/${invoice.id}`}
            sx={{'&:hover': {color: theme.palette.common.white}}}
            target='_blank'
            variant='contained'
            color={viewButtonColor}
            size='small'
          >
            View
          </Button>

          <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" />

          <Dropdown.Menu>
            <Dropdown.Item onClick={(e) => (invoice.id && setSelected(invoice.id!, !checked))}>Select</Dropdown.Item>

            {/* {
              invoice.thirdPartyInvoiceId
              && <Dropdown.Item
                as={Button}
                // onClick={(e) => setShowSendModal([invoice.id])}
                disabled={!hasDownload}
              >Send</Dropdown.Item>
            } */}
            {
              invoice.thirdPartyInvoiceId
              && <Dropdown.Item
                as={Button}
                onClick={async (e) => {
                  const url = `${REACT_APP_API_ROOT_URI}/downloads/download_invoice_pdf/${invoice.invoiceNumber}`;
                  const response = await typedApiFetch(url);
                  const file = window.URL.createObjectURL(await response.blob());
                  const a = Object.assign(document.createElement('a'), {
                    href: file,
                    download: `${invoice.invoiceNumber}.pdf`,
                  });
                  document.body.appendChild(a);
                  a.click(); a.remove();
                }}
                // href={`${REACT_APP_API_ROOT_URI}/downloads/download_invoice_pdf/${invoice.invoiceNumber}`}
                disabled={!hasDownload}
              >Download PDF</Dropdown.Item>
            }
          </Dropdown.Menu>
        </Dropdown>
        {/* {invoice.thirdPartyInvoiceId && <>{` | `}<Link to={`/freshbooks_invoices/${invoice.thirdPartyInvoiceId}`} target='_blank'>Edit Address</Link></>} */}
      </StyledTableCell>
    </StyledTableRow>
  );
};

/** `React.memo()` will return a `JSX.Element` that only gets re-rendered when its props change.
 * The default behavior is that a child component will re-render *whenever* its parent does, regardless
 * of whether or not the child's props changed.
 *
 * It's important to define this outside of the component it is used within.
 */
const InvoiceListRow = React.memo(InvoiceListDataRow);

export const InvoicesListView = () => {
  // const query = useQuery();
  // const runId = query.get("billingRunId");
  // const freshbooksClientId = query.get("freshbooksClientId");
  // const oldFreshbooksClientId = query.get("oldFreshbooksClientId");
  // const clientId = query.get("clientId");

  const invoices = useAppSelector(state => state.invoices.invoices);
  const loadedState = useAppSelector(state => state.invoices.loadedState);
  const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
  const [selectedInvoices, setSelectedInvoices] = useState<number[]>([]);
  const [allChecked, setAllChecked] = useState(false as boolean);
  const [allOnPageChecked, setAllOnPageChecked] = useState(false as boolean);

  const [checkedStatusFilters, setCheckedStatusFilters] = useState<HashOf<boolean>>(invoiceStatusFilterOptions);

  const [ showSearch, setShowSearch ] = useState(true);
  const [ page, setPage ] = useState(1);
  const [ pageInput, setPageInput ] = useState('1');
  const [ pageSize, setPageSize ] = useState(20);
  const getLastPage = () => Math.ceil((invoices ?? []).length / pageSize);

  const [searchClients, setSearchClients] =useState<Nullable<IClientDropdownItem[]>>(null);
  // const [searchClientId, setSearchClientId] = useState('');

  const [searchOwnershipGroupId, setSearchOwnershipGroupId] = useState<number | undefined | null>(undefined);

  const [searchInvoiceNumber, setSearchInvoiceNumber] = useState('');
  const [searchLineDescription, setSearchLineDescription] = useState('');
  // const [searchPageSize, setSearchPageSize] = useState(50);
  const [accountType, setAccountType] = useState('client' as 'client' | 'partner' | 'both');
  const [invoiceType, setInvoiceType] = useState<string>('');

  const [ partnerSelected, setPartnerSelected ] = useState(false);

  const [sortCriteria, setSortCriteria] = useState(['id', 'asc']);

  const [showSendModal, setShowSendModal] = useState(false);

  // Default to last 30 days
  const [invoiceDateStart, setInvoiceDateStart] = useState<Date>(
    subtractDays(new Date(), 30),
  );
  const [invoiceDateEnd, setInvoiceDateEnd] = useState<Date>(getEndOfDay(new Date()));

  const invoiceDownloadQueueLength = useAppSelector((state) => state.invoices.invoiceDownloadQueueLength);
  // const invoiceEmailSendQueueLength = useAppSelector((state) => state.invoices.invoiceEmailSendQueueLength);

  const helped = useCallback((sortCriteriaName: string, invoice: IInvoice) => {
    const sortHelpers = {
      'invoiceNumber': (invoice: IInvoice) => (invoice.invoiceNumber ?? `PV:${invoice.id}`),
      'clientName': (invoice: IInvoice) => (
        (
          (invoice.billingAccount.clients ?? []).length === 1
            ? (invoice.billingAccount.clients ?? []).map(c => c.clientName).join(', ')
            : (
              `${(invoice.billingAccount.clients ?? [])
                .filter(c => c.isParentClient)
                .map(c => c.clientName).join(', ')}`
            )
        ).trim()
      ).toLowerCase(),
      'amountInvoiced': (invoice: IInvoice) => (
        floatVal(invoice.amountInvoiced.replace('$', '').trim().split(' ')[0])
      ),
      'generationType': (invoice: IInvoice) => (
        invoice.generationType === 'manual' ? 'OTF' : 'Monthly'
      ),
    } as Hash;
    return (
      sortHelpers[sortCriteriaName]
        ? sortHelpers[sortCriteriaName](invoice)
        : (invoice)[sortCriteriaName as keyof IInvoice]
    );
  }, []);

  const doSort = useCallback(
    (a: IInvoice, b: IInvoice) => {
      const [ field, sortOrder ] = sortCriteria;
      return (
        sortOrder === 'asc'
          ? (helped(field, a) > helped(field, b) ? -1 : 1)
          : (helped(field, b) > helped(field, a)) ? -1 : 1);
    }, [ sortCriteria, helped ],
  );

  const sortedInvoices = useMemo(() => (invoices ?? []).slice().sort(doSort), [ doSort, invoices ]);

  const toggleOptions = useMemo(() => (
    [
      {
        value: '',
        label: 'All',
      },
      {
        value: 'manual',
        label: 'OTF',
      },
      {
        value: 'monthly',
        label: 'Monthly',
      },
    ]
  ), []);

  const pagedInvoices = useMemo(
    () => sortedInvoices.slice((page - 1) * pageSize, page * pageSize),
    [ page, pageSize, sortedInvoices ],
  );

  const sortedNonLegacyInvoiceIds = useMemo(() => (
    sortedInvoices
      .filter((i) => i.hydraState !== EHydraState['legacy-imported'])
      .map((i) => i.id)
  ), [ sortedInvoices ]);

  const pagedInvoiceIds = useMemo(() => pagedInvoices.map((i) => i.id), [ pagedInvoices ]);

  const pagedNonLegacyInvoiceIds = useMemo(() => (
    pagedInvoices
      .filter((i) => i.hydraState !== EHydraState['legacy-imported'])
      .map((i) => i.id)
  ), [ pagedInvoices ]);

  const multiItemActions: HashOf<(invoiceIds: number[]) => void> = {
    'publish': (invoiceIds: number[]) => {
      // console.info('Publishing invoice IDs: ', { invoiceIds });
      publishInvoices(invoiceIds);
    },
    'send': ((invoiceIds: number[]) => {
      // console.info('Sending emails for invoice IDs: ', { invoiceIds });
      setShowSendModal(true);
    }),
    'generatePdf': (invoiceIds: number[]) => {
      // console.info('Generating PDFs for invoice IDs: ', { invoiceIds });
      triggerInvoicePdfGeneration(invoiceIds);
    },
  };

  const multiItemAction = (eventKey: string, invoiceIds: number[]) => {
    if (hasKey(multiItemActions, eventKey)) {
      multiItemActions[eventKey](invoiceIds);
    }
  };
  useEffect(() => {
    const subTotal: number = invoices?.reduce((acc, curr) => acc + floatVal(curr.amountInvoiced ?? '0'), 0) || 0;
    setInvoiceSubtotal(subTotal);
  }, [invoices]);

  useEffect(() => { setPageInput(`${page}`); }, [ page, setPageInput ]);

  useEffect(() => {
    if (!searchOwnershipGroupId && searchClients && searchClients.length > 1) setSearchOwnershipGroupId(null);
  }, [searchClients, setSearchOwnershipGroupId, searchOwnershipGroupId]);

  const getFreshbookClientId = useMemo(() => {
    if (!searchClients) {
      return null;
    }
    return (
      searchClients.length === 1
        ? searchClients[0].freshbooksClientId
        : null
    );
  }, [searchClients]);

  /**
   * Set an invoice row's checkbox to either checked or unchecked, explicitly.
   * @param invoiceId
   * @param newValue `true` (checked) | `false` (unchecked)
   */
  const setSelected = useCallback(
    (invoiceId: number, newValue: boolean) => {
      setSelectedInvoices(
        (selectedInvoices) => [
          ...selectedInvoices.filter((id) => id !== invoiceId),
          ...(newValue === true ? [ invoiceId ] : []),
        ],
      );
    },
    [],
  );

  /** Set all checkboxes on the page to `checked` if any are unchecked; otherwise uncheck all. */
  const toggleAllInvoiceCheckboxesForCurrentPage = () => {
    setSelectedInvoices((selectedInvoices) => [
      ...selectedInvoices.filter((i) => !pagedInvoiceIds.includes(i)),
      ...(allOnPageChecked ? [] : pagedInvoiceIds),
    ]);
  };

  const getSelectedInvoiceIds = () => selectedInvoices;

  const getUnpublishedInvoiceCount = (filterBySelected: boolean = true) => (
    (sortedInvoices || [])
      .filter((i) => (!filterBySelected || getSelectedInvoiceIds().includes(i.id)) && !i.thirdPartyInvoiceId)
      .length
  );
  const getPublishedInvoiceCount = (filterBySelected: boolean = true) => (
    (sortedInvoices || [])
      .filter((i) => !filterBySelected || getSelectedInvoiceIds().includes(i.id))
      .length - getUnpublishedInvoiceCount(filterBySelected)
  );

  const getSelectedInvoices = () => sortedInvoices.filter((invoice) => selectedInvoiceIdsSet.has(invoice.id));

  const toggleSearch = () => {
    setShowSearch((showSearch) => !showSearch);
  };

  const search = () => {
    const allStatuses = Object.keys(checkedStatusFilters);
    const selectedStatuses = allStatuses.filter(s => checkedStatusFilters[s]);
    const [start, end] = [
      invoiceDateStart?.toISOString(),
      invoiceDateEnd?.toISOString(),
    ];
    // console.info(`InvoicesListView: `, { start: start?.indexOf('T'), end: end?.indexOf('T') });
    loadInvoices(compactObject({
      clientIds: (searchClients ?? []).map((c) => c.id),
      ownershipGroupId: (
        (searchOwnershipGroupId ?? 0) > 0
          ? intVal(searchOwnershipGroupId)
          : undefined
      ),
      accountType,
      invoiceV3Statuses: selectedStatuses,
      invoiceNumber: searchInvoiceNumber.trim().length > 0 ? searchInvoiceNumber.trim() : null,
      invoiceDateStart: start?.slice(0, start?.indexOf('T')),
      invoiceDateEnd: end?.slice(0, end?.indexOf('T')),
      lineDescription: searchLineDescription.trim().length > 0 ? searchLineDescription.trim() : null,
      invoiceType,
      // pageSize: searchPageSize,
    }));
    setSelectedInvoices([]);
  };
  const sortBy = (criteria: string) => {
    setSortCriteria(
      (sortCriteria) => [criteria, (sortCriteria[0] === criteria && sortCriteria[1] === 'asc') ? 'desc' : 'asc'],
    );
  };
  const getSortIcon = (criteria: string) => {
    return sortCriteria[0] === criteria ? (sortCriteria[1] === 'asc' ? <>&#x25bc;</> : <>&#x25b2;</>) : null;
  };

  const toggleAllInvoiceCheckboxes = () => {
    setSelectedInvoices([
      ...(
        !allChecked
          ? sortedNonLegacyInvoiceIds
          : []
      ),
    ]);
  };

  const selectedInvoiceIdsSet = useMemo(() => new Set(selectedInvoices), [ selectedInvoices ]);

  useEffect(() => {
    setAllChecked(sortedNonLegacyInvoiceIds.length === selectedInvoices.length);

    // Iterate over page IDs: O(n) | Look-up in the set: O(1) | Creating the set (done before): O(n)
    setAllOnPageChecked(pagedNonLegacyInvoiceIds.filter((id) => selectedInvoiceIdsSet.has(id)).length === pageSize);
  }, [
    pageSize,
    pagedNonLegacyInvoiceIds,
    selectedInvoices.length,
    selectedInvoiceIdsSet,
    sortedNonLegacyInvoiceIds.length,
  ]);

  return (
    <>
      {
        showSendModal
        && (
          <SendDocumentsModal
            show={showSendModal}
            onHide={() => setShowSendModal(false)}
            onSend={() => setSelectedInvoices([])}
            documents={getSelectedInvoices()}
          />
        )
      }
      <Grid container spacing={2} mt={2} justifyContent="center" alignItems="center">
        <Grid item xs={10}>
          {showSearch && <Grid container spacing={1} alignItems="center">
            <Grid item xs={2}>Client:</Grid>
            <Grid item xs={10}>
              <ClientMultiSelectDropdown
                onClientChanged={
                  (client) => {
                    const partnersInClientArray = (client ?? []).filter((c) => c.type === 'partner');
                    setSearchClients(client);
                    setPartnerSelected(partnersInClientArray.length > 0);
                  }
                }
              />
            </Grid>

            <Grid item xs={2}>Ownership Group:</Grid>
            <Grid item xs={10}>
              <OwnershipGroupDropdown
                allowFreeform
                freshbooksClientId={getFreshbookClientId}
                ownershipGroupId={searchOwnershipGroupId}
                onOwnershipGroupChanged={(ownershipGroup) => {
                  setSearchOwnershipGroupId(ownershipGroup ? intVal(ownershipGroup.id) : undefined);
                }}
                onOwnershipGroupFreeForm={(ownershipGroupString) => {
                  // TODO: Remove free-form support for ths one.
                }}
                disableIfNoOwnershipGroups={true}
                disableComponent={partnerSelected || (searchClients ?? []).length !== 1}
              />
            </Grid>

            <Grid item xs={2}>Invoice Number:</Grid>
            <Grid item container xs={10} alignItems='center'>
              <Grid item>
                <TextField
                  label='Invoice Number'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchInvoiceNumber(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      search();
                    }
                  }}
                  size="small"
                  sx={{my: '0.5rem'}}
                  value={searchInvoiceNumber}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <small
                  style={{ margin: 0, color: 'slategray', marginLeft: 8 }}
                >
                  Press &crarr; to search...
                </small>
              </Grid>
            </Grid>
            <Grid item xs={2}>Line Description:</Grid>
            <Grid item container xs={10} alignItems='center'>
              <Grid item>
                <TextField
                  label='Line Description'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchLineDescription(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      search();
                    }
                  }}
                  size="small"
                  sx={{my: '0.5rem'}}
                  value={searchLineDescription}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <small
                  style={{ margin: 0, color: 'slategray', marginLeft: 8 }}
                >
                  Press &crarr; to search...
                </small>
              </Grid>
            </Grid>
            <Grid item xs={2}>Statuses:</Grid>
            <Grid item xs={10} sx={{mb: 1.5}}>
              {/* Let's try implementing https://www.npmjs.com/package/react-multiselect-dropdown-bootstrap */}
              <StatusDropdown
                statusFilterOptions={checkedStatusFilters}
                onCustomOptionSelected = {
                  (optionId) => {
                    const selectedKeys = optionId.split('-');
                    const allKeys:string[] = keys(checkedStatusFilters);
                    const selectedAction = invoiceStatusCustomOptions.filter(
                      (option) => optionId === option.id,
                    )[0].action;
                    setCheckedStatusFilters(selectedAction(allKeys, selectedKeys));
                  }
                }
                onItemToggle = {
                  (itemId) => {
                    const allKeys: string[] = keys(checkedStatusFilters);
                    const newMap: HashOf<boolean> = allKeys.reduce((acc, key) => {
                      return {
                        ...acc,
                        [key]: (
                          key === itemId
                            ? !(checkedStatusFilters)[key]
                            : (checkedStatusFilters)[key]
                        ),
                      };
                    }, {});
                    setCheckedStatusFilters(newMap);
                  }
                }
              />
            </Grid>
            <Grid item xs={2} mt={1.2}>Date Range:</Grid>
            <Grid item xs={10} mt={1.2}>
              <DateRangeDropdown
                onStartDateChange= {(d) => {
                  const newDate = d ? getEndOfDay(d) : invoiceDateStart;
                  setInvoiceDateStart(newDate);
                }}
                onEndDateChange={(d) => setInvoiceDateEnd(d ? getEndOfDay(d) : new Date())}
                startDate={invoiceDateStart}
                endDate={invoiceDateEnd}
                onItemToggle={(itemId) => { }}
                onCustomOptionSelected={(optionId) => {
                  setInvoiceDateStart(
                    subtractDays(new Date(), intVal(optionId)),
                  );
                  setInvoiceDateEnd(getEndOfDay(new Date()));
                }}
              />
            </Grid>
            <Grid item xs={2} mt={1.2}>Account Type:</Grid>
            <Grid item xs={10} mt={1.2}>
              <DropdownMultiselect
                title={``}
                customOptions={[
                  {
                    location: 'prepend',
                    id: 'client',
                    label: 'Client',
                  },
                  {
                    location: 'prepend',
                    id: 'partner',
                    label: 'Partner',
                  },
                ]}
                onCustomOptionSelected={(optionId) => { setAccountType(optionId as 'client' | 'partner' | 'both'); }}
                selectionMap={{}}
                onItemToggle={(itemId) => { }}
                disabled={false}
              />
              &nbsp;
              &nbsp;
              &nbsp;
              {ucaseFirst(accountType)}
            </Grid>
            <Grid item xs={2} mt={1.2}>
              Invoice Type:
            </Grid>
            <Grid item xs={10} mt={1.2}>
              <HHMultiStateToggle
                options={toggleOptions}
                defaultValues={''}
                onChange={(value: string | string[]) => {
                  if (Array.isArray(value)) {
                    return;
                  }
                  setInvoiceType(value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={loadedState === 'loading'}
                onClick={search}
                style={{ verticalAlign: 'middle' }}
                variant='contained'
              >
                Search
                {loadedState === 'loading' && <CircularProgress size={25} color='primary' sx={{ml: 2}} />}
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Button
                variant='outlined'
                onClick={() => { toggleSearch(); }}
                sx={{ verticalAlign: 'middle', mb: 1 }}
              >
                Hide Search Form
              </Button>
            </Grid>
          </Grid>}
          {!showSearch && <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                variant='contained'
                onClick={() => { toggleSearch(); }}
                sx={{ width: '100%', verticalAlign: 'middle', my: 1 }}
              >
                Reveal Search Form
              </Button>
            </Grid>
          </Grid>}
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={4} xl={5} style={{ textAlign: 'left' }}>
              {(invoices ?? []).length} total invoices over {getLastPage()} pages.{' '}
              ({roundFloatToCents(invoiceSubtotal)} Total)
            </Grid>
            <Grid item container xs={8} xl={7} style={{ textAlign: 'right' }}>
              <Grid item xs={0} xl={1}></Grid>
              <Grid item xs={4} px={2} textAlign='center' />

              <Grid item xs={4} px={2} textAlign='center'>
                <Card
                  elevation={3}
                  sx={{
                    p: 1,
                    boxShadow: '0 0 6px rgba(35, 173, 278, 1);',
                  }}
                >
                  <Typography>
                    PDFs processing:&nbsp;&nbsp;
                    <span style={{ fontSize: '1.2rem' }}>{`${invoiceDownloadQueueLength}`}</span>
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={4} xl={3}>
                <DropdownButton
                  as={ButtonGroup}
                  id={`selected-action-dropdown`}
                  variant='primary'
                  title={`Selected (${selectedInvoices.length} invoices)`}
                  onSelect={(eventKey) => { multiItemAction(`${eventKey}`, getSelectedInvoiceIds()); }}
                  disabled={loadedState === 'loading'}
                >
                  <Dropdown.Item eventKey="publish">Publish ({getUnpublishedInvoiceCount()}) as Draft</Dropdown.Item>
                  {/* <Dropdown.Item eventKey="download">Download ({getUnpublishedInvoiceCount()}) Invoices (to server)</Dropdown.Item> */}
                  <Dropdown.Item eventKey="send">
                  Send ({getPublishedInvoiceCount()}) Invoices to Client(s)
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="generatePdf">
                  Generate ({getPublishedInvoiceCount()}) PDFs for Invoices
                  </Dropdown.Item>
                </DropdownButton>
              </Grid>
            </Grid>

            <br></br>
            <DownloadCSV filename={'invoice_search'}>
              {/* <Table striped bordered hover variant="dark"> */}
              <Grid container>
                <Grid item xs={3} style={{textAlign: 'left'}}>
                  <Button variant='outlined' onClick={() => toggleAllInvoiceCheckboxes()}>
                    {allChecked ? 'De-select' : 'Select'} all {(invoices ?? []).length} invoices
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                  <Button onClick={() => setPage(1)}>&lt;&lt;</Button>
                  <Button onClick={() => setPage((page) => page > 1 ? page - 1 : 1)}>&lt;</Button>
                  {` Page `}&nbsp;&nbsp;
                  <input
                    style={{width: '45px'}}
                    value={pageInput}
                    onChange={(e) => {
                      setPageInput(e.target.value);
                      if(e.target.value && !Number.isNaN(intVal(e.target.value))) {
                        setPage(clamp(intVal(e.target.value), 1, getLastPage()));
                      }
                    }}
                  />
                  {` / ${getLastPage()} `}
                  <Button onClick={() => setPage((page) => page < getLastPage() ? page + 1 : getLastPage())}>
                    &gt;
                  </Button>
                  <Button onClick={() => setPage(getLastPage())}>&gt;&gt;</Button>

                </Grid>
                <Grid item xs={3} sx={{mb: 0.5, textAlign: 'right'}}>
                  Page Size: {pageSize}
                  &nbsp;&nbsp;&nbsp;
                  <DropdownMultiselect
                    title={``}
                    customOptions={[
                      {
                        location: 'prepend',
                        id: '20',
                        label: '20',
                      },
                      {
                        location: 'prepend',
                        id: '50',
                        label: '50',
                      },
                      {
                        location: 'prepend',
                        id: '100',
                        label: '100',
                      },
                      {
                        location: 'prepend',
                        id: '150',
                        label: '150',
                      },
                      {
                        location: 'prepend',
                        id: '200',
                        label: '200',
                      },
                      {
                        location: 'prepend',
                        id: '250',
                        label: '250',
                      },
                      {
                        location: 'prepend',
                        id: '500',
                        label: '500',
                      },
                      {
                        location: 'prepend',
                        id: '1000',
                        label: '1000',
                      },
                      {
                        location: 'prepend',
                        id: '2000',
                        label: '2000',
                      },
                    ]}
                    onCustomOptionSelected={(optionId) => { setPageSize(intVal(optionId)); }}
                    selectionMap={{}}
                    onItemToggle={(itemId) => { }}
                    disabled={false}
                  />{' '}
                </Grid>
              </Grid>
              <TableContainer component={Paper} elevation={12} sx={{mb: 1, overflow: 'visible'}}>
                <MuiTable style={{ borderCollapse: 'collapse' }}>
                  <TableHead>
                    <StyledTableRow dark={true} style={{ cursor: 'pointer' }}>
                      <StyledTableCell
                        dark={true}
                        style={{ textAlign: 'center' }}
                        onClick={() => toggleAllInvoiceCheckboxesForCurrentPage()}
                      >
                        <FormCheck
                          checked={allOnPageChecked && (invoices ?? []).length > 0}
                          onChange={() => toggleAllInvoiceCheckboxesForCurrentPage()}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        dark={true}
                        onClick={(e: MouseEvent<HTMLTableCellElement>) => sortBy('invoiceNumber')}
                        style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                      >
                        Invoice # {getSortIcon('invoiceNumber')}
                      </StyledTableCell>
                      <StyledTableCell
                        dark={true}
                        onClick={(e: MouseEvent<HTMLTableCellElement>) => sortBy('clientName')}
                        style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                      >Client {getSortIcon('clientName')}
                      </StyledTableCell>
                      <StyledTableCell
                        dark={true}
                        style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                      >
                        Description
                      </StyledTableCell>
                      <StyledTableCell
                        dark={true}
                        onClick={(e: MouseEvent<HTMLTableCellElement>) => sortBy('invoiceDate')}
                        style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                      >
                        Date {getSortIcon('invoiceDate')}
                      </StyledTableCell>
                      <StyledTableCell
                        dark={true}
                        onClick={(e: MouseEvent<HTMLTableCellElement>) => sortBy('amountInvoiced')}
                        style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                      >
                        Subtotal {getSortIcon('amountInvoiced')}
                      </StyledTableCell>
                      {/* Possibly helpful for developers only */}
                      {/* <th>Run {getSortIcon('')}</th> */}
                      {/* <th>Generation Type {getSortIcon('')}</th> */}
                      <StyledTableCell
                        dark={true}
                        onClick={(e: MouseEvent<HTMLTableCellElement>) => sortBy('state')}
                        style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                      >
                        State {getSortIcon('state')}
                      </StyledTableCell>
                      <StyledTableCell
                        dark={true}
                        onClick={(e: MouseEvent<HTMLTableCellElement>) => sortBy('generationType')}
                        style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                      >
                        Type {getSortIcon('generationType')}
                      </StyledTableCell>
                      <StyledTableCell>&nbsp;</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {
                      (pagedInvoices ?? [])
                        .map((i) => (
                          <InvoiceListRow
                            key={i.id}
                            invoice={i}
                            checked={selectedInvoices.includes(i.id)}
                            setSelected={setSelected}
                          />
                        ))
                    }
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </DownloadCSV>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};
